import React from 'react';
import Header from '../Shared/Header';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../Shared/PrivateRoute';
import ListRoute from './List';

const BulkShipProducts = () => (
  <div>
    <Header />
    <Switch>
      <PrivateRoute path="/bulk-ship-products" component={ListRoute} />
    </Switch>
  </div>
);

export default BulkShipProducts;
