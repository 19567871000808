import React from "react";
import Header from "../Shared/Header";
import { Switch } from "react-router-dom";
import PrivateRoute from "../Shared/PrivateRoute";
import ToolsPage from "./Tools";

const Layouts = () => (
  <div>
    <Header />
    <Switch>
      <PrivateRoute path="/tools" component={ToolsPage} />
    </Switch>
  </div>
);

export default Layouts;
