export const LOGIN_REQUESTING = 'LOGIN_REQUESTING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const LOGOUT_REQUESTING = 'LOGOUT_REQUESTING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOGIN_EXISTING = 'LOGIN_EXISTING';

export const STORAGE_IS_ADMIN = 'isAdmin';

export const GET_PROFILE_FLAGS_REQUESTING = 'GET_PROFILE_FLAGS_REQUESTING';
export const GET_PROFILE_FLAGS_SUCCESS = 'GET_PROFILE_FLAGS_SUCCESS';
export const GET_PROFILE_FLAGS_ERROR = 'GET_PROFILE_FLAGS_ERROR';

export const SET_PROFILE_FLAGS_REQUESTING = 'SET_PROFILE_FLAGS_REQUESTING';
export const SET_PROFILE_FLAGS_SUCCESS = 'SET_PROFILE_FLAGS_SUCCESS';
export const SET_PROFILE_FLAGS_ERROR = 'SET_PROFILE_FLAGS_ERROR';

export const INIT = 'INIT';
export const RESET_STATE = 'RESET_STATE';
export const RESET_STATE_ERROR = 'RESET_STATE_ERROR';

export const JWT_EXPIRED = 'JWT_EXPIRED';
