import { call, put, takeLatest, select, all } from 'redux-saga/effects';

import loginSchema from './schema';
import * as actions from './actions';
import * as types from './constants';
import { normalize } from 'normalizr';
import loginApi from '../api/auth-api';
import * as storage from '../utils/storage';
import { STORAGE_IS_ADMIN } from './constants';
import * as profileApi from '../api/profile-api';
import errorHandler from '../utils/errorResponseHandler';

const JWT_TOKEN = process.env.REACT_APP_JWT_TOKEN;
const LOGIN_ENTITIES = process.env.REACT_APP_LOGIN_ENTITIES;
const LAST_USER = process.env.REACT_APP_LAST_USER;

export const parseLoginResponse = response => {
  response.data.lab = response.data.lab || Object.assign({}, response.data.labs[0]);
  const data = normalize(response.data, loginSchema);
  const { result: jwt, entities } = data;
  const user_id = response.data.user.id;
  const isAdmin = response.data.user.email.indexOf('@photoday.io') !== -1;

  return {
    jwt,
    user_id,
    user_hash: entities.login[jwt].user_hash,
    entities,
    isAuthenticated: true,
    isAdmin
  };
};

export const storeValues = (payload, rememberMe, isAdmin) => {
  const { jwt, entities } = payload;

  storage.set(JWT_TOKEN, jwt, rememberMe);
  storage.set(LOGIN_ENTITIES, JSON.stringify(entities), true);
  storage.set(STORAGE_IS_ADMIN, isAdmin, true);
};

export const getLoginEntities = state => {
  const {
    login: { entities }
  } = state;

  return entities;
};

export const setProfileFlagsWebStorage = entities => {
  storage.set(LOGIN_ENTITIES, JSON.stringify(entities), true);
};

function* logoutFlow() {
  try {
    const jwt = yield call(storage.get, JWT_TOKEN);
    const json = yield call(storage.get, LOGIN_ENTITIES);
    const entities = yield call(JSON.parse, json);

    yield call(storage.clearAll);
    if (jwt && entities && entities.login[jwt]) {
      const login = entities.login[jwt];
      storage.set(LAST_USER, login.user);
    }
    yield put(actions.logoutSuccess());
  } catch (error) {
    yield call(errorHandler, actions.loginError, error);
  }
}

function* loginFlow(action) {
  const {
    payload: { email, password, rememberMe }
  } = action;

  try {
    const response = yield call(loginApi, email, password);
    const payload = yield call(parseLoginResponse, response);

    yield call(storeValues, payload, rememberMe, payload.isAdmin);
    yield put(actions.loginSuccess(payload));
    return payload.jwt;
  } catch (error) {
    yield call(errorHandler, actions.loginError, error);
  }
  return null;
}

function* getProfileFlagsFlow() {
  try {
    const { data: flags } = yield call(profileApi.getProfileFlags);

    yield put(actions.getProfileFlagsSuccess(flags));
  } catch (error) {
    yield call(errorHandler, actions.getProfileFlagsError, error);
  }
}

function* setProfileFlagsFlow(action) {
  try {
    const {
      payload: { args }
    } = action;
    const { data: flags } = yield call(profileApi.setProfileFlags, args);

    yield put(actions.setProfileFlagsSuccess(flags));
    const entities = yield select(getLoginEntities);
    const jwt = yield call(storage.get, JWT_TOKEN);

    if (jwt && entities) {
      yield call(setProfileFlagsWebStorage, entities);
      yield put(actions.resetLoginState({ jwt, entities }));
    }
  } catch (error) {
    yield call(errorHandler, actions.setProfileFlagsError, error);
  }
}

function* initLoginStateFlow() {
  try {
    const jwt = yield call(storage.get, JWT_TOKEN);
    const json = yield call(storage.get, LOGIN_ENTITIES);
    let isAdmin = yield call(storage.get, STORAGE_IS_ADMIN);
    isAdmin = isAdmin === true || isAdmin === 'true';

    if (!isAdmin) isAdmin = false; // In case undefined
    const entities = yield call(JSON.parse, json);
    // Reset login state only for valid store
    if (jwt && entities && entities.login[jwt]) {
      const { user_hash, user: user_id } = entities.login[jwt];
      yield put(actions.resetLoginState({ jwt, user_id, user_hash, entities, isAdmin }));
    }
  } catch (error) {
    yield call(errorHandler, actions.resetLoginStateError, error);
  }
}

export function* loginWatcher() {
  yield all([
    takeLatest(types.LOGIN_REQUESTING, loginFlow),
    takeLatest(types.GET_PROFILE_FLAGS_REQUESTING, getProfileFlagsFlow),
    takeLatest(types.SET_PROFILE_FLAGS_REQUESTING, setProfileFlagsFlow),
    takeLatest(types.INIT, initLoginStateFlow),
    takeLatest(types.LOGOUT_REQUESTING, logoutFlow)
  ]);
}
