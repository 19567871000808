import React, { Component } from "react";
import EditableTable from "../../Shared/EditableTable";
import * as api from "../../api/lab-api";
import { reactFormatter } from "react-tabulator";
import EditableImageCell from "../../Shared/EditableTable/EditableImageCell";
import ProductLayoutsCell from "./ProductLayoutsCell";
import { connect } from "react-redux";
import { labIdSelector } from "../../selectors";

class ProductsTable extends Component {
  state = { data: [], productCategories: null, labLayouts: null, error: null };

  productCategoryFormatter = cell => {
    const _cell = cell._cell;
    const categories = _cell.column.definition.editorParams.values;
    let categoryName = cell._cell.row.data.category_name;

    if (_cell.oldValue) {
      for (let ind in categories) {
        const category = categories[ind];
        if (category.value === _cell.value) {
          categoryName = category.label;
          break;
        }
      }
    }

    return categoryName;
  };

  columns = [
    {
      title: "",
      field: "published_at",
      width: 53,
      headerSort: false,
      cssClass: "draft"
    },
    {
      title: "ID*",
      field: "ex_id",
      width: 125,
      editor: "textarea",
      formatter: "textarea",
      headerSort: false
    },
    {
      title: "P-510x340",
      field: "image_url",
      width: 90,
      headerSort: false,
      formatter: reactFormatter(
        <EditableImageCell updateFunc={api.updateProduct} />
      )
    },
    {
      title: "Name*",
      field: "name",
      width: 180,
      editor: "input",
      formatter: "textarea"
    },
    {
      title: "Category*",
      field: "category_name",
      width: 110,
      editor: "select",
      formatter: this.productCategoryFormatter,
      variableHeight: true,
      cssClass: "pre-wrap"
    },
    {
      title: "Price*",
      field: "price_without_symbol",
      width: 85,
      editor: "input",
      editUnpublishedOnly: true,
      formatter: "money",
      formatterParams: { symbol: "$" }
    },
    {
      title: "Layouts",
      field: "layouts",
      width: 220,
      headerSort: false,
      formatter: reactFormatter(<ProductLayoutsCell />),
      tableComponent: this,
      cssClass: "overflow-visible"
    },
    {
      title: "PP",
      field: "is_paper_print",
      width: 41,
      headerSort: false,
      editor: true,
      formatter: "tickCross"
    },
    {
      title: "AC",
      field: "is_adaptive_crop",
      width: 41,
      headerSort: false,
      editor: true,
      formatter: "tickCross"
    },
    {
      title: "Description",
      field: "description",
      width: 278,
      editor: "textarea",
      formatter: "textarea",
      headerSort: false
    },
    { field: "layout_ids", visible: false }
  ];

  constructor(props) {
    super(props);
    this.getProductCategories();
    this.getLabLayouts();
  }

  getProductCategories() {
    const { labId } = this.props;
    api.getProductCategories({ lab_id: labId }).then(
      result => {
        this.setState({ productCategories: result.data });
      },
      error => {
        alert("Error in loading Product Categories");
      }
    );
  }

  getLabLayouts() {
    const { labId } = this.props;
    api.getLabLayouts(labId).then(
      result => {
        this.setState({ labLayouts: result.data });
      },
      error => {
        alert("Error in loading Lab Layouts");
      }
    );
  }

  getData() {
    const { labId } = this.props;

    api.getLabProducts(labId).then(
      result => {
        this.setState({ data: result.data });
      },
      error => {
        alert("Error in loading Lab Products");
      }
    );
  }

  reposition(row, newPosition) {
    const rowData = row.getData();
    const data = row.getTable().getData();
    let priority = 0;

    for (let ind in data) {
      const item = data[ind];
      if (item.category.id === rowData.category.id) {
        priority += 1;
        if (item.id === rowData.id) break;
      }
    }

    if (priority > 0) {
      const payload = { id: rowData.id, display_priority: priority };
      api.updateProduct(payload);
    }
  }

  render() {
    const { labId } = this.props;
    const createParams = { lab_id: labId };
    const { productCategories, labLayouts } = this.state;

    if (productCategories) {
      let categories = [];
      for (let ind in productCategories) {
        const category = productCategories[ind];
        categories.push({ label: category.name, value: category.name });
      }
      for (let ind in this.columns) {
        let column = this.columns[ind];
        if (column.field === "category_name") {
          column.editorParams = { values: categories };
        }
      }
    }

    return (
      productCategories &&
      labLayouts && (
        <EditableTable
          title="Products"
          columns={this.columns}
          initialSort={this.initialSort}
          data={this.state.data}
          getDataFunc={() => this.getData()}
          createParams={createParams}
          createFunc={api.createProduct}
          updateFunc={api.updateProduct}
          deleteFunc={api.deleteProduct}
          className="editable-table"
          repositionFunc={this.reposition}
        />
      )
    );
  }
}

const mapStateToProps = state => ({
  labId: labIdSelector(state)
});

export default connect(mapStateToProps)(ProductsTable);
