import React, { useState } from "react";
import * as api from "../api/order-api";

export default function ToolsPage() {
  const [orderNum, setOrderNum] = useState();
  const [sendBtnDisabled, setSendBtnDisabled] = useState(true);

  const resendOrder = () => {
    setSendBtnDisabled(true);

    api.orderResend(orderNum).then(
      result => {
        setSendBtnDisabled(false);
        alert("Order was successful resent");
      },
      error => {
        setSendBtnDisabled(false);
        alert("Order not found or you don't have required permissions");
      }
    );
  };

  const onOrderNumChanged = e => {
    const val = e.target.value;
    const empty = val === "";

    setOrderNum(val);
    setSendBtnDisabled(empty);
  };

  return (
    <div>
      <aside className="subnavigation">
        <nav className="subnavigation__container">
          <header className="subnavigation__header subnavigation__hide-mobile">Tools</header>
        </nav>
      </aside>
      <div className="container" style={{ marginTop: "15px" }}>
        <h4 style={{ paddingBottom: "10px" }}>
          <b>Order Resend</b>{" "}
        </h4>
        <input
          type="text"
          size="20"
          style={{ marginRight: "10px" }}
          placeholder="Order Number"
          onChange={onOrderNumChanged}
        />
        <button className="btn btn-primary" onClick={resendOrder} disabled={sendBtnDisabled}>
          Resend
        </button>
      </div>
    </div>
  );
}
