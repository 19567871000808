import { post } from '../utils/http';

const login = (email, password) => {
  const endpoint = 'labs/authenticate';
  const args = {
    email,
    password
  };
  return post(endpoint, args, false);
};

export default login;
