import React from 'react';
import Header from '../Shared/Header';
import { Switch } from 'react-router-dom';
import PrivateRoute from '../Shared/PrivateRoute';
import ListRoute from './List';

const Studios = () => (
  <div>
    <Header />
    <Switch>
      <PrivateRoute path="/studios" component={ListRoute} />
    </Switch>
  </div>
);

export default Studios;
