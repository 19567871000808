import React from 'react';
import Header from '../Shared/Header';
import { Link } from 'react-router-dom';
import not_found_image from '../images/404_image.png';

import './404.css';

const NotFound404 = ({ location }) => {
  const errorContainer = (
    <div className="container">
      <div className="error-container">
        <h2> We couldn't find the page you were looking for.</h2>
        <img src={not_found_image} alt="page_not_found" />
        <p>
          Error 404 URL: <code>{location.pathname}</code> <br />
          Sorry, the page you're looking for cannot be found. <br />
          Either check the URL,&nbsp;
          <Link to="/dashboard">go to the dashboard</Link>, or feel free to&nbsp;
          <a href="mailto:support@photoday.io">report this issue</a>.
        </p>
      </div>
    </div>
  );
  return (
    <div id="not_found_404_index">
      <Header />
      {errorContainer}
    </div>
  );
};

export default NotFound404;
