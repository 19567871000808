import React, { Component } from 'react';
import EditableTable from '../../Shared/EditableTable';
import * as api from '../../api/lab-api';

class LayoutNodesTable extends Component {
  state = { isLoaded: false, data: [], error: null };

  nodeTypes = ['image', 'text', 'logo'];

  columns = [
    { formatter: "rownum", align: "center", width: 40, headerSort: false },
    { title: 'ID', field: 'node_key', width: 150, editor: 'input', headerSort: false },
    { title: 'DP2 Num', field: 'node_number', width: 150, editor: 'input', headerSort: false },
    {
      title: 'Type',
      field: 'layout_node_type',
      width: 70,
      editor: 'select',
      headerSort: false,
      editorParams: { values: this.nodeTypes }
    },
    { title: 'Description', field: 'description_custom', width: 200, editor: 'input', formatter: 'textarea' },
    { title: 'Aspect', field: 'aspect', width: 70, editor: 'input', headerSort: false, editable: this.aspectEditable },
    {
      title: 'System?',
      field: 'is_system',
      width: 80,
      headerSort: false,
      align: 'center',
      editor: true,
      formatter: 'tickCross'
    },
    {
      title: 'Theme Graphic?',
      field: 'theme_graphic',
      width: 130,
      headerSort: false,
      align: 'center',
      editor: true,
      formatter: 'tickCross'
    },
    { title: 'TG width', field: 'width', width: 80, editor: 'input', headerSort: false, editable: this.graphicDimensionsEditable },
    { title: 'TG height', field: 'height', width: 85, editor: 'input', headerSort: false, editable: this.graphicDimensionsEditable },
    { title: 'Options', field: 'node_options', width: 120, editor: 'input', headerSort: false }
  ];

  initialSort = [
    { column: 'layout_node_type', dir: 'asc' },
    { column: 'description_custom', dir: 'asc' }
  ];

  aspectEditable(cell) {
    const cells = cell._cell.row.cells;
    let res = false;
    for (let ind in cells) {
      if (cells[ind].column.field === 'layout_node_type' && cells[ind].value === 'image') {
        res = true;
        break;
      }
    }
    return res;
  }

  graphicDimensionsEditable(cell) {
    const cells = cell._cell.row.cells;
    let res = false;
    for (let ind in cells) {
      if (cells[ind].column.field === 'theme_graphic' && cells[ind].value === true) {
        res = true;
        break;
      }
    }
    return res;
  }

  getData() {
    const layoutId = this.props.layout.id;
    api.getLayoutNodes(layoutId).then(
      result => {
        this.setState({ isLoaded: true, data: result.data });
      },
      error => {
        alert('Error in loading Layout Nodes');
      }
    );
  }

  render() {
    const layout = this.props.layout;
    const createParams = { lab_id: layout.lab_id, layout_id: layout.id };
    const title = `Layout Nodes (layout "${layout.ex_id}")`;

    return (
      <EditableTable
        title={title}
        columns={this.columns}
        initialSort={this.initialSort}
        data={this.state.data}
        getDataFunc={() => this.getData()}
        createParams={createParams}
        createFunc={api.createLayoutNode}
        updateFunc={api.updateLayoutNode}
        deleteFunc={api.deleteLayoutNode}
        className="editable-table"
      />
    );
  }
}

export default LayoutNodesTable;
