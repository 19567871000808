import React, { Component } from 'react';
import EditableTable from '../../Shared/EditableTable';
import * as api from '../../api/lab-api';
import { reactFormatter } from 'react-tabulator';
import EditableImageCell from '../../Shared/EditableTable/EditableImageCell';
import ColorCell from './ColorCell';

class ThemePropertyValuesTable extends Component {
  state = { isLoaded: false, data: [], error: null };

  columns = [
    { title: 'Position', field: 'position', width: 100, editor: 'number', editorParams: { min: 0 } },
    { title: 'ID', field: 'ex_id', width: 150, editor: 'input', headerSort: false },
    { title: 'Value', field: 'value', width: 200, editor: 'input' },
    {
      title: 'Color',
      field: 'color_hex_formatted',
      width: 120,
      headerSort: false,
      formatter: reactFormatter(<ColorCell container='.theme-property-values-modal' />),
      tableComponent: this,
      cssClass: 'overflow-visible'
    },
    {
      title: 'Width=500',
      field: 'icon_url',
      width: 90,
      headerSort: false,
      align: 'center',
      formatter: reactFormatter(<EditableImageCell imageWidth={30} attachmentField="icon" updateFunc={api.updateThemePropertyValue} />)
    },
    { field: 'color_hex', visible: false }
  ];

  initialSort = [{ column: 'value', dir: 'asc' }];

  getData() {
    const { themeProperty } = this.props;

    api.getThemePropertyValues(themeProperty.id).then(
      result => {
        this.setState({ isLoaded: true, data: result.data });
      },
      error => {
        alert('Error in loading Theme Property Values');
      }
    );
  }

  render() {
    const { themeProperty } = this.props;
    const createParams = { lab_theme_property_id: themeProperty && themeProperty.id };

    return (
      themeProperty && (
        <EditableTable
          title="Theme Property Values"
          columns={this.columns}
          initialSort={this.initialSort}
          data={this.state.data}
          getDataFunc={() => this.getData()}
          createParams={createParams}
          createFunc={api.createThemePropertyValue}
          updateFunc={api.updateThemePropertyValue}
          deleteFunc={api.deleteThemePropertyValue}
          className="editable-table"
        />
      )
    );
  }
}

export default ThemePropertyValuesTable;
