import { expiredToken } from '../Login/actions';
import { put, call } from 'redux-saga/effects';
import { errorToast } from './toast';

function* errorHandler(fn, error) {
  const { message, response } = error;
  const { status: code, data: errorData } = response || {};

  if (code === 401) {
    yield put(expiredToken());
  } else {
    const errorMessage =
      errorData && errorData.error
        ? errorData.error_localized
          ? errorData.error_localized
          : errorData.error
          ? errorData.error
          : message
        : message;
    yield call(errorToast, errorMessage);
  }

  return yield put(fn(error));
}

export default errorHandler;
