import React, { Component } from 'react';
import EditableTable from '../../Shared/EditableTable';
import * as api from '../../api/lab-api';
import { connect } from 'react-redux';
import { labIdSelector } from '../../selectors';

class BulkShipProductsTable extends Component {
  state = { data: [], productCategories: null, labLayouts: null, error: null };

  columns = [
    {
      title: 'Position',
      field: 'display_priority',
      width: 100,
      align: 'center'
    },
    { title: 'ID', field: 'ex_id', width: 200, formatter: 'textarea', headerSort: false },
    { title: 'Name', field: 'name', width: 200, formatter: 'textarea' },
    {
      title: 'Category',
      field: 'category_name',
      width: 200,
      variableHeight: true,
      cssClass: 'pre-wrap'
    },
    {
      title: 'Drop Price',
      field: 'price_without_symbol',
      width: 120,
      editor: 'input',
      formatter: 'money',
      formatterParams: { symbol: '$' }
    },
    {
      title: 'Bulk Price',
      field: 'bulk_price_without_symbol',
      width: 120,
      editor: 'input',
      formatter: 'money',
      formatterParams: { symbol: '$' }
    },
    {
      title: 'Bulk Multiplier',
      field: 'bulk_multiplier',
      width: 150,
      editor: 'number',
      align: 'center',
      editorParams: { min: 1 },
      headerSort: false
    }
  ];

  getData() {
    const { labId } = this.props;

    api.getLabProducts(labId).then(
      result => {
        this.setState({ data: result.data });
      },
      error => {
        alert('Error in loading Lab Products');
      }
    );
  }

  render() {
    return (
      <EditableTable
        title="Products"
        columns={this.columns}
        initialSort={this.initialSort}
        data={this.state.data}
        getDataFunc={() => this.getData()}
        updateFunc={api.updateProduct}
        className="editable-table"
      />
    );
  }
}

const mapStateToProps = state => ({
  labId: labIdSelector(state)
});

export default connect(mapStateToProps)(BulkShipProductsTable);
