export const GET_MYACCOUNT_STUDIO_REQUESTING = 'GET_MYACCOUNT_STUDIO_REQUESTING';
export const GET_MYACCOUNT_STUDIO_SUCCESS = 'GET_MYACCOUNT_STUDIO_SUCCESS';
export const GET_MYACCOUNT_STUDIO_ERROR = 'GET_MYACCOUNT_STUDIO_ERROR';

export const UPDATE_MYACCOUNT_STUDIO_REQUESTING = 'UPDATE_MYACCOUNT_STUDIO_REQUESTING';
export const UPDATE_MYACCOUNT_STUDIO_SUCCESS = 'UPDATE_MYACCOUNT_STUDIO_SUCCESS';
export const UPDATE_MYACCOUNT_STUDIO_ERROR = 'UPDATE_MYACCOUNT_STUDIO_ERROR';

export const GET_MYACCOUNT_REQUESTING = 'GET_MYACCOUNT_REQUESTING';
export const GET_MYACCOUNT_SUCCESS = 'GET_MYACCOUNT_SUCCESS';
export const GET_MYACCOUNT_ERROR = 'GET_MYACCOUNT_ERROR';

export const UPDATE_MYACCOUNT_REQUESTING = 'UPDATE_MYACCOUNT_REQUESTING';
export const UPDATE_MYACCOUNT_SUCCESS = 'UPDATE_MYACCOUNT_SUCCESS';
export const UPDATE_MYACCOUNT_ERROR = 'UPDATE_MYACCOUNT_ERROR';

export const UPDATE_STRIPE_ACCOUNT_REQUESTING = 'UPDATE_STRIPE_ACCOUNT_REQUESTING';
export const UPDATE_STRIPE_ACCOUNT_SUCCESS = 'UPDATE_STRIPE_ACCOUNT_SUCCESS';
export const UPDATE_STRIPE_ACCOUNT_ERROR = 'UPDATE_STRIPE_ACCOUNT_ERROR';

export const DELETE_STRIPE_ACCOUNT_REQUESTING = 'DELETE_STRIPE_ACCOUNT_REQUESTING';
export const DELETE_STRIPE_ACCOUNT_SUCCESS = 'DELETE_STRIPE_ACCOUNT_SUCCESS';
export const DELETE_STRIPE_ACCOUNT_ERROR = 'DELETE_STRIPE_ACCOUNT_ERROR';

export const GET_USER_STUDIOS_REQUESTING = 'GET_USER_STUDIOS_REQUESTING';
export const GET_USER_STUDIOS_SUCCESS = 'GET_USER_STUDIOS_SUCCESS';
export const GET_USER_STUDIOS_ERROR = 'GET_USER_STUDIOS_ERROR';

export const CHANGE_CURRENT_LAB_REQUESTING = 'CHANGE_CURRENT_LAB_REQUESTING';
export const CHANGE_CURRENT_LAB_ERROR = 'CHANGE_CURRENT_LAB_ERROR';

export const CREATE_NEXUS = 'CREATE_NEXUS';
export const CREATE_NEXUS_SUCCESS = 'CREATE_NEXUS_SUCCESS';
export const CREATE_NEXUS_ERROR = 'CREATE_NEXUS_ERROR';

export const GET_NEXUS_LIST = 'GET_NEXUS_LIST';
export const GET_NEXUS_LIST_SUCCESS = 'GET_NEXUS_LIST_SUCCESS';
export const GET_NEXUS_LIST_ERROR = 'GET_NEXUS_LIST_ERROR';

export const DELETE_NEXUS = 'DELETE_NEXUS';
export const DELETE_NEXUS_SUCCESS = 'DELETE_NEXUS_SUCCESS';
export const DELETE_NEXUS_ERROR = 'DELETE_NEXUS_ERROR';

export const GET_PHOTOLYNX_PLIC_OAUTH_URL = 'GET_PHOTOLYNX_PLIC_OAUTH_URL';
export const GET_PHOTOLYNX_PLIC_OAUTH_URL_SUCCESS = 'GET_PHOTOLYNX_PLIC_OAUTH_URL_SUCCESS';
export const GET_PHOTOLYNX_PLIC_OAUTH_URL_ERROR = 'GET_PHOTOLYNX_PLIC_OAUTH_URL_ERROR';

export const CREATE_PHOTOLYNX_PLIC_CONNECT = 'CREATE_PHOTOLYNX_PLIC_CONNECT';
export const CREATE_PHOTOLYNX_PLIC_CONNECT_SUCCESS = 'CREATE_PHOTOLYNX_PLIC_CONNECT_SUCCESS';
export const CREATE_PHOTOLYNX_PLIC_CONNECT_ERROR = 'CREATE_PHOTOLYNX_PLIC_CONNECT_ERROR';

export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR';

export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_ERROR = 'GET_SUBSCRIPTION_ERROR';

export const GET_SUBSCRIPTION_PLANS = 'GET_SUBSCRIPTION_PLANS';
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS';
export const GET_SUBSCRIPTION_PLANS_ERROR = 'GET_SUBSCRIPTION_PLANS_ERROR';

export const GET_SUBSCRIPTION_COUPON = 'GET_SUBSCRIPTION_COUPON';
export const GET_SUBSCRIPTION_COUPON_SUCCESS = 'GET_SUBSCRIPTION_COUPON_SUCCESS';
export const GET_SUBSCRIPTION_COUPON_ERROR = 'GET_SUBSCRIPTION_COUPON_ERROR';

export const REMOVE_SUBSCRIPTION_COUPON = 'REMOVE_SUBSCRIPTION_COUPON';

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR';

export const GET_STUDIO_LABS_IN_STUDIO = 'GET_STUDIO_LABS_IN_STUDIO';
export const GET_STUDIO_LABS_IN_STUDIO_SUCCESS = 'GET_STUDIO_LABS_IN_STUDIO_SUCCESS';
export const GET_STUDIO_LABS_IN_STUDIO_ERROR = 'GET_STUDIO_LABS_IN_STUDIO_ERROR';

export const ADD_STUDIO_LAB_TO_STUDIO = 'ADD_STUDIO_LAB_TO_STUDIO';
export const ADD_STUDIO_LAB_TO_STUDIO_SUCCESS = 'ADD_STUDIO_LAB_TO_STUDIO_SUCCESS';
export const ADD_STUDIO_LAB_TO_STUDIO_ERROR = 'ADD_STUDIO_LAB_TO_STUDIO_ERROR';

export const GET_ALL_LABS = 'GET_ALL_LABS';
export const GET_ALL_LABS_SUCCESS = 'GET_ALL_LABS_SUCCESS';
export const GET_ALL_LABS_ERROR = 'GET_ALL_LABS_ERROR';
