import React from 'react';
import './versionTag.css';

function VersionTag() {
  const env = process.env.REACT_APP_ENVIRONMENT;
  if (env === 'production') return null;
  return (
    <div className="version-tag">
      <h6 className="version-tag__heading">Version {process.env.REACT_APP_VERSION}</h6>
      <small>{process.env.REACT_APP_ENVIRONMENT || 'local'}</small>
      <br />
      {process.env.REACT_APP_GIT_CHECKSUM && <small>git: {process.env.REACT_APP_GIT_CHECKSUM}</small>}
    </div>
  );
}

export default VersionTag;
