import axios from 'axios';
import qs from 'qs';
import * as storage from './storage';
import defaultPager from './defaultPager';
import entitiesToMap from './entitiesToMap';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const JWT_TOKEN = process.env.REACT_APP_JWT_TOKEN;
const LOGIN_ENTITIES = process.env.REACT_APP_LOGIN_ENTITIES;
const APP_VERSION = process.env.REACT_APP_VERSION;

const buildFullUrl = endpoint => (endpoint.indexOf(BASE_URL) === -1 ? `${BASE_URL}${endpoint}` : endpoint);

const getJwtToken = () => {
  const jwt = storage.get(JWT_TOKEN);
  return jwt ? `Bearer ${jwt}` : null;
};

const SESSION_HASH = 'SESSION_HASH';

export const getSessionHash = () => {
  let hash = storage.get(SESSION_HASH);
  if (!hash) {
    hash = Math.random()
      .toString(36)
      .slice(2);
    storage.set(SESSION_HASH, hash);
  }
  return hash;
};

const urlEncodeParams = (params = {}) => {
  const empty = Object.keys(params).length === 0 && params.constructor === Object;
  return empty ? '' : `?${qs.stringify(params)}`;
};

const labHeaders = () => {
  const json = storage.get(LOGIN_ENTITIES);
  const entities = JSON.parse(json);
  const { user: userEntites, lab: labEntites } = entities;
  const user = entitiesToMap(userEntites)[0];
  const lab = entitiesToMap(labEntites)[0];

  return user.is_photoday ? { Lab: lab.id } : {};
};

const httpHeaders = (auth = {}, contentType = {}, version = {}, restricted = true) => {
  const config = restricted
    ? Object.assign({}, auth, contentType, version, labHeaders(), photodayClient())
    : Object.assign({}, contentType, version, photodayClient());
  return { headers: config };
};

const authHeader = () => {
  const token = getJwtToken();
  return token ? { Authorization: `${token}` } : {};
};

const jsonContentType = () => ({
  'Content-Type': 'application/json'
});

const formDataContentType = () => ({
  'Content-Type': 'multipart/form-data'
});

const versionHeader = () => {
  const version = APP_VERSION;
  return version ? { 'X-App-Version': `${version}` } : {};
};

const photodayClient = () => {
  return { 'Photoday-Client': 'Studio-Web' };
};

const formatJsonParams = params => JSON.stringify(params);

const formatFormDataParams = params => {
  const formData = new FormData();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      formData.append(key, value.constructor === File ? value : formatJsonParams(value));
    }
  }
  return formData;
};

const errorParser = error => {
  const { message, response } = error;
  return { message, response };
};

export const getPaginationParams = headers => {
  const { 'x-page': xPage, 'x-per-page': xPerPage, 'x-total': xTotal } = headers;
  const page = xPage ? parseInt(xPage, 10) : defaultPager.page;
  const perPage = xPerPage ? parseInt(xPerPage, 10) : defaultPager.perPage;
  const total = xTotal ? parseInt(xTotal, 10) : defaultPager.total;
  const totalPages = Math.ceil(total / perPage);
  return { page, perPage, total, totalPages };
};

export const get = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const encodedParams = urlEncodeParams(params);
  const finalUrl = `${url}${encodedParams}`;
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), restricted);
  return new Promise((resolve, reject) => {
    axios
      .get(finalUrl, headers)
      .then(resp => resolve(resp))
      .catch(errResp => reject(errorParser(errResp)));
  });
};

export const post = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatJsonParams(params);
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), restricted);
  return new Promise((resolve, reject) => {
    axios
      .post(url, args, headers)
      .then(resp => resolve(resp))
      .catch(errResp => reject(errorParser(errResp)));
  });
};

export const multipartPost = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatFormDataParams(params);
  const headers = httpHeaders(authHeader(), formDataContentType(), versionHeader(), restricted);
  return new Promise((resolve, reject) => {
    axios
      .post(url, args, headers)
      .then(resp => resolve(resp))
      .catch(errResp => reject(errorParser(errResp)));
  });
};

export const del = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatJsonParams(params);
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), restricted).headers;
  const config = {
    method: 'DELETE',
    url,
    data: args,
    headers
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then(resp => resolve(resp))
      .catch(errResp => reject(errorParser(errResp)));
  });
};

export const put = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatJsonParams(params);
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), restricted);
  return new Promise((resolve, reject) => {
    axios
      .put(url, args, headers)
      .then(resp => resolve(resp))
      .catch(errResp => reject(errorParser(errResp)));
  });
};

export const patch = (endpoint, params = {}, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const args = formatJsonParams(params);
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), restricted);
  return new Promise((resolve, reject) => {
    axios
      .patch(url, args, headers)
      .then(resp => resolve(resp))
      .catch(errResp => reject(errorParser(errResp)));
  });
};

export const getBlob = (endpoint, params = {}, fileName, restricted = true) => {
  const url = buildFullUrl(endpoint);
  const encodedParams = urlEncodeParams(params);
  const finalUrl = `${url}${encodedParams}`;
  const headers = httpHeaders(authHeader(), jsonContentType(), versionHeader(), restricted);

  function showFile(blob) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const newBlob = new Blob([blob], { type: 'application/pdf' });

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob, fileName);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob);

    let link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();

    setTimeout(function() {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }

  fetch(finalUrl, headers)
    .then(r => r.blob())
    .then(blob => showFile(blob));
};
