import React, { Component } from 'react';
import '@simonwep/pickr/dist/themes/nano.min.css';
import Pickr from '@simonwep/pickr';

class ColorCell extends Component {
  constructor(props) {
    super(props);

    const cell = this.props.cell._cell;
    this.state = { value: cell.value };
  }

  initColorPicker() {
    const cell = this.props.cell._cell;
    const defaultColor = this.state.value ? this.state.value : '#000000';
    const el = cell.element.querySelector('.color-picker');
    const container = this.props.container || 'body';

    Pickr.create({
      el: el,
      container: container,
      theme: 'nano',
      default: defaultColor,
      components: {
        preview: true,
        hue: true,
        interaction: {
          input: true,
          clear: true,
          save: true
        }
      }
    }).on('save', (color, instance) => {
      let newColor = null;
      if (color) newColor = color.toHEXA().toString();
      if (this.state.value !== newColor) {
        instance.hide();
        const colorHexCell = cell.row.cells.find(el => el.column.field === 'color_hex');
        colorHexCell.setValue(newColor.replace('#', ''));
        this.setState({ value: newColor });
      }

    }).on('init', (instance) => {
      if (!this.state.value) instance._clearColor();
    });
  }

  componentDidMount() {
    this.initColorPicker();
    const row = this.props.cell._cell.row;
    row.normalizeHeight(true);
  }

  render() {
    return (
      <div>
        <span className="color-picker" />
      </div>
    );
  }
}

export default ColorCell;
