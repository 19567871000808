import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { reducer as login } from './Login/reducer';

const IndexReducer = combineReducers({
  login,
  form: reduxFormReducer
});

export default IndexReducer;
