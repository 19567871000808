import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const errorToast = message => {
  const options = {
    type: toast.TYPE.ERROR,
    autoClose: 5000
  };

  return toast(message, options);
};

export const successToast = message => {
  const options = {
    type: toast.TYPE.SUCCESS,
    autoClose: 3000
  };
  return toast(message, options);
};
