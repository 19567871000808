import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Helpers
import { INIT } from "./Login/constants";
import PrivateRoute from "./Shared/PrivateRoute";
import ReturningUserRoute from "./Shared/ReturningUserRoute";

// Components
import ProductsRoute from "./Products";
import LayoutsRoute from "./Layouts";
import StudiosRoute from "./Studios";
import BulkShipProductsRoute from "./BulkShipProducts";
import BackgroundsRoute from "./Backgrounds";
import LoginRoute from "./Login";
import NotFound404 from "./NotFound404";
import DashboardRoute from "./Dashboard";
import ErrorBoundary from "./ErrorBoundary";
import ToolsRoute from "./Tools";
import VersionTag from "./VersionTag/index";

// Redux Store
import store from "./store";
store.dispatch({ type: INIT });

const Routes = () => (
  <Provider store={store}>
    <Router>
      <ErrorBoundary>
        <Switch>
          <ReturningUserRoute exact path="/" component={LoginRoute} />
          <PrivateRoute exact path="/dashboard" component={DashboardRoute} />
          <PrivateRoute path="/products" component={ProductsRoute} />
          <PrivateRoute path="/layouts" component={LayoutsRoute} />
          <PrivateRoute path="/studios" component={StudiosRoute} />
          <PrivateRoute path="/bulk-ship-products" component={BulkShipProductsRoute} />
          <PrivateRoute path="/backgrounds" component={BackgroundsRoute} />
          <PrivateRoute path="/tools" component={ToolsRoute} />
          <Route component={NotFound404} />
        </Switch>

        <VersionTag />
      </ErrorBoundary>
    </Router>
  </Provider>
);

export default Routes;
