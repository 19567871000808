import { schema } from 'normalizr';

const flags = new schema.Entity('flags');
const user = new schema.Entity('user', { flags });
const lab = new schema.Entity('lab');
const labItem = new schema.Entity('labs');
const login = new schema.Entity(
  'login',
  {
    user,
    lab,
    labs: [labItem]
  },
  { idAttribute: 'jwt' }
);

export default login;
