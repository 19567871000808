import entitiesToMap from './utils/entitiesToMap';

export const labSelector = state => {
  const {
    login: { entities }
  } = state;
  const lab = entitiesToMap(entities.lab)[0];

  return lab;
};

export const labIdSelector = state => {
  const lab = labSelector(state);
  return lab.id;
};
