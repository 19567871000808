import * as api from '../utils/http';

export const getLab = labId => {
  const endpoint = `labs/${labId}`;

  return api.get(endpoint);
};

export const getAllLabs = () => {
  const endpoint = `labs`;

  return api.get(endpoint);
};

export const getStudios = payload => {
  const { lab_id, query } = payload;
  let endpoint = `labs/${lab_id}/studios`;
  if (query) endpoint = `${endpoint}?search=${query}`;

  return api.get(endpoint);
};

export const updateStudio= studio => {
  const endpoint = `studios/${studio.id}`;

  return api.put(endpoint, studio);
};


export const getProductCategories = payload => {
  const { lab_id } = payload;
  const endpoint = `labs/${lab_id}/product-categories`;

  return api.get(endpoint);
};

export const createProductCategory = product_category => {
  const endpoint = `labs/${product_category.lab_id}/product-categories`;

  return api.post(endpoint, product_category);
};

export const updateProductCategory = product_category => {
  const endpoint = `product-categories/${product_category.id}`;

  return api.put(endpoint, product_category);
};

export const deleteProductCategory = id => {
  const endpoint = `product-categories/${id}`;

  return api.del(endpoint);
};

export const getThemeProperties = labId => {
  const endpoint = `labs/${labId}/theme-properties`;

  return api.get(endpoint);
};

export const getThemeProperty = id => {
  const endpoint = `lab-theme-properties/${id}`;

  return api.get(endpoint);
};

export const createThemeProperty = payload => {
  const endpoint = `labs/${payload.lab_id}/theme-properties`;

  return api.post(endpoint, payload);
};

export const updateThemeProperty = payload => {
  const endpoint = `lab-theme-properties/${payload.id}`;

  return api.put(endpoint, payload);
};

export const deleteThemeProperty = id => {
  const endpoint = `lab-theme-properties/${id}`;

  return api.del(endpoint);
};

export const getThemePropertyValues = themePropertyId => {
  const endpoint = `lab-theme-properties/${themePropertyId}/values`;

  return api.get(endpoint);
};

export const createThemePropertyValue = payload => {
  const endpoint = `lab-theme-properties/${payload.lab_theme_property_id}/values`;

  return api.post(endpoint, payload);
};

export const updateThemePropertyValue = payload => {
  const endpoint = `theme-property-values/${payload.id}`;

  return api.put(endpoint, payload);
};

export const deleteThemePropertyValue = id => {
  const endpoint = `theme-property-values/${id}`;

  return api.del(endpoint);
};

export const getLabProducts = labId => {
  const endpoint = `labs/${labId}/products`;

  return api.get(endpoint);
};

export const createProduct = payload => {
  const endpoint = `labs/${payload.lab_id}/products`;

  return api.post(endpoint, payload);
};

export const updateProduct = payload => {
  const endpoint = `products/${payload.id}`;

  return api.put(endpoint, payload);
};

export const deleteProduct = id => {
  const endpoint = `products/${id}`;

  return api.del(endpoint);
};

export const getLabLayouts = labId => {
  const endpoint = `labs/${labId}/layouts`;

  return api.get(endpoint);
};

export const createLayout = payload => {
  const endpoint = `labs/${payload.lab_id}/layouts`;

  return api.post(endpoint, payload);
};

export const updateLayout = payload => {
  const endpoint = `layouts/${payload.id}`;

  return api.put(endpoint, payload);
};

export const deleteLayout = id => {
  const endpoint = `layouts/${id}`;

  return api.del(endpoint);
};

export const getLayoutNodes = layoutId => {
  const endpoint = `layouts/${layoutId}/layout-nodes`;

  return api.get(endpoint);
};

export const createLayoutNode = payload => {
  const endpoint = `layouts/${payload.layout_id}/layout-nodes`;

  return api.post(endpoint, payload);
};

export const updateLayoutNode = payload => {
  const endpoint = `layout-nodes/${payload.id}`;

  return api.put(endpoint, payload);
};

export const deleteLayoutNode = id => {
  const endpoint = `layout-nodes/${id}`;

  return api.del(endpoint);
};

export const getLayoutThemes = (layoutId, propValue) => {
  let endpoint = `layouts/${layoutId}/layout-themes`;
  if (propValue) endpoint = `${endpoint}?theme_property_value_id=${propValue}`;

  return api.get(endpoint);
};

export const createLayoutTheme = payload => {
  const endpoint = `layouts/${payload.layout_id}/layout-themes`;

  return api.post(endpoint, payload);
};

export const updateLayoutTheme = payload => {
  const endpoint = `layout-themes/${payload.id}`;

  return api.put(endpoint, payload);
};

export const deleteLayoutTheme = id => {
  const endpoint = `layout-themes/${id}`;

  return api.del(endpoint);
};

export const getShippingRates = payload => {
  const { lab_id } = payload;
  const endpoint = `labs/${lab_id}/shipping-rates`;

  return api.get(endpoint);
};

export const createShippingRate = shipping_rate => {
  const endpoint = `labs/${shipping_rate.lab_id}/shipping-rates`;

  return api.post(endpoint, shipping_rate);
};

export const updateShippingRate = shipping_rate => {
  const endpoint = `shipping-rates/${shipping_rate.id}`;

  return api.put(endpoint, shipping_rate);
};

export const deleteShippingRate = id => {
  const endpoint = `shipping-rates/${id}`;

  return api.del(endpoint);
};

export const getBackgroundCollections = labId => {
  const endpoint = `labs/${labId}/background-collections`;

  return api.get(endpoint);
};

export const createBackgroundCollection = data => {
  const endpoint = `labs/${data.lab_id}/background-collections`;

  return api.post(endpoint, data);
};

export const updateBackgroundCollection = data => {
  const endpoint = `background-collections/${data.id}`;

  return api.put(endpoint, data);
};

export const deleteBackgroundCollection = id => {
  const endpoint = `background-collections/${id}`;

  return api.del(endpoint);
};

export const getBackgrounds = collectionId => {
  const endpoint = `background-collections/${collectionId}/backgrounds`;

  return api.get(endpoint);
};

export const createBackground= data => {
  const endpoint = `background-collections/${data.background_collection_id}/backgrounds`;

  return api.post(endpoint, data);
};

export const updateBackground = data => {
  const endpoint = `backgrounds/${data.id}`;

  return api.put(endpoint, data);
};

export const deleteBackground = id => {
  const endpoint = `backgrounds/${id}`;

  return api.del(endpoint);
};
