import * as c from './constants';
import isEmpty from 'lodash.isempty';
import entitiesToMap from '../utils/entitiesToMap';
import { handleRequestingState, handleErrorState } from '../utils/reducerHelpers';

const initialState = {
  entities: {
    user: {},
    login: {},
    lab: {},
    labs: {},
    flags: {}
  },
  isAuthenticated: false,
  isAdmin: false,
  invalidLogin: false,
  showExpiredSessionModal: false,
  jwt: null,
  user_id: null,
  user_hash: null,
  profile_flags: {},
  requesting: false,
  successful: false,
  errors: []
};

export const handleLoginSuccessState = (state, payload) => {
  const { entities, jwt, user_id, user_hash, isAuthenticated, isAdmin } = payload;
  const { user: userEntity, lab: labEntity, labs: labsEntity } = entities;
  const user = entitiesToMap(userEntity)[0];
  const lab = entitiesToMap(labEntity)[0];
  const labs = entitiesToMap(labsEntity);

  return {
    ...state,
    ...{
      entities,
      jwt,
      user_id,
      user_hash,
      user,
      lab,
      labs,
      isAuthenticated,
      isAdmin,
      errors: [],
      requesting: false,
      successful: true,
      showExpiredSessionModal: false
    }
  };
};

export const handleLogoutState = (state, initialState) => Object.assign({}, state, initialState);

export const handleSessionExpired = state => Object.assign({}, state, { showExpiredSessionModal: true });

export const handleProfileFlagsSuccess = (state, payload) => {
  const { flags } = payload;
  const { entities } = state;
  const { flags: origFlag } = entities;
  const newFlags = Object.assign({}, origFlag, { [flags.id]: flags });
  const newEntities = Object.assign({}, entities, { flags: newFlags });
  return {
    ...state,
    ...{
      entities: newEntities,
      requesting: false,
      successful: true,
      errors: []
    }
  };
};

export const handleResetLoginState = (state, payload) => {
  const { entities, jwt, user_id, user_hash, isAdmin } = payload;
  const { user: userEntity, lab: labEntity, labs: labsEntity } = entities;
  const user = entitiesToMap(userEntity)[0];
  const lab = entitiesToMap(labEntity)[0];
  const labs = entitiesToMap(labsEntity);

  return {
    ...state,
    ...{
      entities,
      jwt,
      user_id,
      user_hash,
      lab,
      labs,
      user,
      isAuthenticated: !isEmpty(jwt),
      isAdmin: isAdmin || false
    }
  };
};

export const handleLoginError = (state, payload) => {
  const errorState = handleErrorState(state, payload);
  const isUnverified = errorState.errors.some(error => {
    return error.body && error.body.error && error.body.error.includes('Unverified');
  });
  return { ...errorState, ...{ isAuthenticated: false, invalidLogin: true, isUnverified } };
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case c.LOGOUT_REQUESTING:
    case c.GET_PROFILE_FLAGS_REQUESTING:
    case c.SET_PROFILE_FLAGS_REQUESTING:
    case c.LOGIN_REQUESTING:
      return handleRequestingState(state);

    case c.GET_PROFILE_FLAGS_ERROR:
    case c.SET_PROFILE_FLAGS_ERROR:
    case c.LOGIN_ERROR:
      return handleLoginError(state, payload);

    case c.LOGIN_SUCCESS:
      return handleLoginSuccessState(state, payload);

    case c.GET_PROFILE_FLAGS_SUCCESS:
    case c.SET_PROFILE_FLAGS_SUCCESS:
      return handleProfileFlagsSuccess(state, payload);

    case c.LOGOUT_SUCCESS:
      return handleLogoutState(state, initialState);

    case c.RESET_STATE:
      return handleResetLoginState(state, payload);

    case c.JWT_EXPIRED:
      return handleSessionExpired(state);

    default:
      return state;
  }
};
