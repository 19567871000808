import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import BackgroundsTable from './BackgroundsTable';
import './styles.css';

class BackgroundsModal extends Component {
  cancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  render() {
    return (
      <Modal className="backgrounds-modal" show={true} onHide={this.cancel} backdrop="static">
        <Modal.Header closeButton={true} onHide={this.cancel} />
        <Modal.Body>
          <BackgroundsTable {...this.props} />
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-close" onClick={this.cancel}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BackgroundsModal;
