import React, { Component } from 'react';
import EditableTable from '../../Shared/EditableTable';
import BackgroundsModal from './BackgroundsModal';
import * as api from '../../api/lab-api';
import { connect } from 'react-redux';
import { labIdSelector, labSelector } from '../../selectors';

class BackgroundCollectionsTable extends Component {
  state = { data: [], error: null, currentCollection: null, editBackgrounds: false };

  columns = [
    { formatter: "rownum", align: "center", width: 50, headerSort: false },
    { title: 'Collection Name *', field: 'name', width: 350, editor: 'input', formatter: "textarea" },
    {
      title: '# of Backgrounds',
      field: 'background_count',
      width: 150,
      headerSort: false,
      align: 'center',
      cssClass: 'cell-link',
      cellClick: this.bgCountClick,
      tableComponent: this
    }
  ];

  initialSort = [{ column: 'name', dir: 'asc' }];

  bgCountClick(e, cell) {
    e.preventDefault();
    const data = cell._cell.row.data;
    const self = cell._cell.column.definition.tableComponent;
    self.setState({ currentCollection: data, editBackgrounds: true });
  }

  getData() {
    const { labId } = this.props;

    api.getBackgroundCollections(labId).then(
      result => {
        this.setState({ data: result.data, currentCollection: null, editBackgrounds: false });
      },
      error => {
        alert('Error in loading Background Collections');
      }
    );
  }

  handleModalClose() {
    this.getData();
  }

  render() {
    const { currentCollection, editBackgrounds } = this.state;
    const { labId, lab } = this.props;
    const createParams = { lab_id: labId };

    return (
      <div>
        {editBackgrounds && (
          <BackgroundsModal
            onCancel={() => this.handleModalClose()}
            collection={currentCollection}
            labId={labId}
            lab={lab}
          />
        )}
        <EditableTable
          title="Background Collections"
          columns={this.columns}
          initialSort={this.initialSort}
          data={this.state.data}
          getDataFunc={() => this.getData()}
          createParams={createParams}
          createFunc={api.createBackgroundCollection}
          updateFunc={api.updateBackgroundCollection}
          deleteFunc={api.deleteBackgroundCollection}
          className="editable-table"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  labId: labIdSelector(state),
  lab: labSelector(state)
});

export default connect(mapStateToProps)(BackgroundCollectionsTable);
