import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

// Components
import { ToastContainer, toast } from 'react-toastify';

// Helpers
import { loginRequest } from './actions';

// Images
import logo from '../images/logo.png';
import logo_oto_hair from '../images/logo_oto_hair.png';

// Styles
import './login.css';

const mapStateToProps = state => ({
  requesting: state.login.requesting,
  invalidLogin: state.login.invalidLogin,
  isUnverified: state.login.isUnverified,
  isAuthenticated: state.login.isAuthenticated
});

class Login extends Component {
  state = {
    email: '',
    password: '',
    showModal: false,
    rememberMe: false,
    showPassword: false
  };

  static propTypes = {
    isUnverified: PropTypes.bool,
    invalidLogin: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    history: PropTypes.object.isRequired,
    loginRequest: PropTypes.func.isRequired
  };

  static defaultProps = {
    history: {},
    isAuthenticated: false,
    invalidLogin: false,
    isUnverified: false
  };

  handleInputOnChange = evt => {
    const { target } = evt;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState({ [name]: value });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    evt.stopPropagation();

    const { loginRequest } = this.props;
    const { email, password, rememberMe } = this.state;

    loginRequest({ email, password, rememberMe });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  render() {
    const { isAuthenticated, invalidLogin, isUnverified, requesting } = this.props;
    const { email, password, showPassword, rememberMe } = this.state;

    if (isUnverified) {
      return <Redirect to="/register/resend-verification" />;
    }

    if (isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div id="login__index">
        <ToastContainer position={toast.POSITION.TOP_CENTER} />
        <section className="onboarding-bg">
          <img src={logo} className="gray-logo" alt="Logo" />

          <main className={'container-fluid flex-page-centered' + (invalidLogin ? ' error' : '')}>
            <div className="white-container col-md-9 login-screen">
              <figure className="blue-gradient left-onboarding-container">
                <img src={logo_oto_hair} alt="Logo Oto" />
                <h1 className="bold-font"> Welcome back!</h1>
                <p> Please enter your email and password to log back in. </p>
              </figure>

              <article className="right-onboarding-container">
                <form onSubmit={this.handleSubmit} className="sign-in-form">
                  <div className={`form-group ${invalidLogin && 'has-error'}`}>
                    <label className="bold-font">Email </label>
                    <input
                      value={email}
                      onChange={this.handleInputOnChange}
                      type="email"
                      name="email"
                      className="tall-form-control"
                      id="exampleInputEmail1"
                      placeholder="johnsmith@email.com"
                      autoComplete="username"
                    />
                  </div>
                  <div className={`form-group ${invalidLogin && 'has-error'}`}>
                    <label className="bold-font">Password</label>
                    <input
                      value={password}
                      onChange={this.handleInputOnChange}
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      className="tall-form-control"
                      id="exampleInputPassword1"
                      placeholder="••••••"
                      autoComplete="current-password"
                    />
                    <small
                      style={{
                        visibility: invalidLogin ? 'visible' : 'hidden'
                      }}
                      className="form-text text-danger"
                    >
                      Invalid Login
                    </small>
                  </div>
                  <div className="password-options">
                    <p />
                    <div className="checkbox">
                      <label className="bold-font">
                        <input
                          name="showPassword"
                          checked={showPassword}
                          onChange={this.handleInputOnChange}
                          type="checkbox"
                        />{' '}
                        Show Password
                      </label>
                    </div>
                  </div>

                  <button ref="submitForm" type="submit" style={{ display: 'none' }} />
                </form>

                <button
                  onClick={() => this.refs.submitForm.click()}
                  type="submit"
                  className="btn btn-primary btn-block login-btn"
                  disabled={requesting}
                >
                  Log In
                </button>

                <div className="checkbox">
                  <label>
                    <input
                      className="bold-font"
                      name="rememberMe"
                      checked={rememberMe}
                      onChange={this.handleInputOnChange}
                      type="checkbox"
                    />{' '}
                    Remember Me
                  </label>
                </div>
              </article>
            </div>

            <footer className="bottom-links">
              <p> &copy; 2018 PhotoDay. All rights reserved. </p>
              <a href="https://www.photoday.io/terms/"> Terms </a>
              <a href="https://www.photoday.io/privacy/"> Privacy </a>
            </footer>
          </main>
        </section>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  { loginRequest }
)(Login);
