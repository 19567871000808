import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/semantic-ui/tabulator_semantic-ui.min.css";
import { ReactTabulator } from "react-tabulator";
import Select from "react-select";
import icon_trash from "../../images/icon_trash-filled.png";
import "./styles.css";

class EditableTable extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    initialSort: PropTypes.array,
    data: PropTypes.array.isRequired,
    createParams: PropTypes.object,
    updateParams: PropTypes.object,
    getDataFunc: PropTypes.func.isRequired,
    updateFunc: PropTypes.func.isRequired,
    createFunc: PropTypes.func,
    deleteFunc: PropTypes.func,
    customAction: PropTypes.string,
    customActionFunc: PropTypes.func,
    customActionType: PropTypes.string,
    titleFilter: PropTypes.string,
    titleFilterValue: PropTypes.string,
    titleFilterOptions: PropTypes.array,
    titleSearch: PropTypes.bool,
    repositionFunc: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.getData(this.props.titleFilterValue);
  }

  getData(titleFilterValue) {
    this.props.getDataFunc(titleFilterValue);
  }

  cellEdited(cell) {
    if (cell.value === "" && cell.oldValue === undefined) return;

    const field = cell.column.field;
    const id = cell.row.data.id;

    let payload = id ? { id } : {};
    payload[field] = cell.value;

    if (id) {
      // update
      const updateParams = this.props.updateParams || {};
      Object.assign(payload, updateParams);
      this.props.updateFunc(payload);
    } else {
      // create
      payload = cell.row.data;
      const createParams = this.props.createParams || {};
      Object.assign(payload, createParams);
      this.props.createFunc(payload).then(result => {
        this.getData();
      });
    }
  }

  addRow() {
    this.ref.table.addRow({}, true);
  }

  userAdmin() {
    return this.props.login.user.is_photoday;
  }

  editableColumn(cell) {
    const def = cell._cell.column.definition;
    const tableComponent = def.editableTableComponent;
    if (def.editUnpublishedOnly && cell._cell.row.data.published_at)
      return false;
    if (tableComponent.userAdmin()) return true;
    return cell._cell.row.data.published_at == null;
  }

  draftFormatter(cell, formatterParams, onRendered) {
    return cell.getValue() ? "" : "Draft";
  }

  delBtnFormatter(cell, formatterParams, onRendered) {
    const data = cell._cell.row.data;
    const { id, published_at } = data;
    if (published_at || !id) return;
    return `<img src="${icon_trash}" class="actions-btn" title="Delete" />`;
  }

  delRow(e, cell) {
    e.preventDefault();
    const data = cell._cell.row.data;
    const rowId = data.id;
    if (data.published_at || !rowId) return;
    const self = cell._cell.column.definition.tableComponent;
    self.props.deleteFunc(rowId).then(
      result => {
        cell._cell.row.delete();
      },
      error => {
        alert("Row deleting failed");
      }
    );
  }

  titleFilterChange(option) {
    this.getData(option.value);
  }

  titleSearchSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const query = form.elements.search.value;

    this.getData(query);
  }

  togglePublished(e, cell) {
    const data = cell._cell.row.data;
    const tableComponent = cell._cell.column.definition.editableTableComponent;
    const value = data.published_at ? null : new Date().toISOString();
    const confirmationText = data.published_at
      ? "Are you sure you want to UNPUBLISH this row?"
      : "Are you sure you want to PUBLISH this row?";
    if (window.confirm(confirmationText)) {
      const payload = { id: data.id, published_at: value };
      tableComponent.props.updateFunc(payload).then(
        result => {
          cell._cell.setValue(value);
        },
        error => {
          alert("Row update failed");
        }
      );
    }
  }

  getColumns() {
    let columns = this.props.columns;
    for (let ind in columns) {
      if (!columns[ind].editable) columns[ind].editable = this.editableColumn;
      if (columns[ind].field === "published_at") {
        columns[ind].formatter = this.draftFormatter;
        if (this.userAdmin() || columns[ind].manage) {
          columns[ind].cellClick = this.togglePublished;
        }
      }
      columns[ind].editableTableComponent = this;
    }

    if (this.props.deleteFunc)
      columns.push({
        title: "",
        width: 40,
        tableComponent: this,
        formatter: this.delBtnFormatter,
        cellClick: this.delRow,
        headerSort: false
      });

    if (this.props.repositionFunc) {
      columns.unshift({
        rowHandle: true,
        formatter: "handle",
        headerSort: false,
        frozen: true,
        width: 40,
        minWidth: 40
      });
    }

    return columns;
  }

  rowMoved(row) {
    const newPosition = row.getPosition();
    this.props.repositionFunc(row, newPosition);
  }

  dataLoaded(data) {
    if (!this.props.dataLoadedFunc) return;
    this.props.dataLoadedFunc(data);
  }

  render() {
    const columns = this.getColumns();
    const {
      initialSort,
      data,
      title,
      className,
      createFunc,
      customAction,
      customActionFunc,
      customActionType,
      titleFilter,
      titleFilterOptions,
      titleFilterValue,
      titleSearch,
      repositionFunc
    } = this.props;

    let titleCSSClasses = [];
    const movableRows = repositionFunc ? true : false;
    let selectedFilterOptions = null;
    if (titleFilter) {
      titleCSSClasses.push("with-title-filter");
      selectedFilterOptions = titleFilterOptions.filter(
        row => titleFilterValue === row.value
      );
    }
    if (titleSearch) titleCSSClasses.push("with-title-search");

    return (
      <div className={className}>
        <h4 className={titleCSSClasses}>
          <b>{title}</b>
          {titleSearch && (
            <form
              className="search-form"
              onSubmit={e => this.titleSearchSubmit(e)}
            >
              <input type="text" name="search" placeholder={titleSearch} />
              <button
                className="btn btn-primary"
                style={{ marginLeft: "10px" }}
              >
                Search
              </button>
            </form>
          )}
          {titleFilter && (
            <div className="title-filter">
              <label>{titleFilter}</label>
              <Select
                value={selectedFilterOptions}
                onChange={opt => this.titleFilterChange(opt)}
                isClearable={false}
                options={titleFilterOptions}
                placeholder="Any"
                className="Select"
              />
            </div>
          )}
          {createFunc && (
            <button
              className="btn btn-primary"
              style={{ marginLeft: "10px" }}
              onClick={() => this.addRow()}
            >
              Add
            </button>
          )}
          {customAction && customActionFunc && customActionType === "upload" && (
            <label className="btn btn-primary" style={{ marginLeft: "10px" }}>
              <input
                type="file"
                multiple
                className="hide"
                accept="image/jpeg"
                onChange={e => customActionFunc(e, this)}
              />
              {customAction}
            </label>
          )}
          {customAction && customActionFunc && !customActionType && (
            <button
              className="btn btn-primary"
              style={{ marginLeft: "10px" }}
              onClick={customActionFunc}
            >
              {customAction}
            </button>
          )}
        </h4>
        <ReactTabulator
          ref={ref => (this.ref = ref)}
          columns={columns}
          initialSort={initialSort}
          data={data}
          cellEdited={cell => this.cellEdited(cell._cell)}
          addRowPos="top"
          options={{ movableRows: movableRows }}
          rowMoved={row => this.rowMoved(row)}
          dataLoaded={data => this.dataLoaded(data)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login
});

export default connect(mapStateToProps)(EditableTable);
