import React, { Component } from 'react';
import { connect } from 'react-redux';

// Components
import Header from '../Shared/Header';
import ProductCategoriesTable from './ProductCategoriesTable';
import ThemePropertiesTable from './ThemePropertiesTable';
import ShippingRatesTable from './ShippingRatesTable';

// Styles
import './dashboard.css';

class Dashboard extends Component {
  render() {
    return (
      <div id="dashboard">
        <Header />
        <aside className="subnavigation">
          <header className="subnavigation__header subnavigation__hide-desktop">
            <h5 className="subnavigation__item">Lab Dashboard</h5>
          </header>
          <nav className="subnavigation__container">
            <header className="subnavigation__header subnavigation__hide-mobile">
              <h5 className="subnavigation__item"> Welcome to your dashboard </h5>
            </header>
          </nav>
        </aside>
        <div className="container">
          <div className="QuickGlanceTables">
            <ProductCategoriesTable />
            <ThemePropertiesTable />
            <ShippingRatesTable />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
