import React, { Component } from 'react';
import { reactFormatter } from 'react-tabulator';
import EditableTable from '../../Shared/EditableTable';
import EditableImageCell from '../../Shared/EditableTable/EditableImageCell';
import * as api from '../../api/lab-api';

class BackgroundsTable extends Component {
  state = { data: [], error: null };

  columns = [
    { formatter: "rownum", align: "center", width: 50, headerSort: false, allStudios: null },
    {
      title: 'Background',
      field: 'image_url',
      width: 200,
      headerSort: false,
      align: 'center',
      cssClass: 'background-photo',
      formatter: reactFormatter(<EditableImageCell imageWidth={150} updateFunc={api.updateBackground} />)
    }
  ];

  initialSort = [];

  getData() {
    const collectionId = this.props.collection.id;

    api.getBackgrounds(collectionId).then(
      result => {
        this.setState({ isLoaded: true, data: result.data });
      },
      error => {
        alert('Error in loading Backgrounds');
      }
    );
  }

  uploadBackgrounds(evt, table) {
    const files = evt.target.files;
    const collection = table.props.collection;
    let upload = { total: files.length, uploaded: 0 };

    for (let ind = 0; ind < files.length; ind++) {
      const file = files[ind];
      const { name, type } = file;
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          const payload = {
            background_collection_id: collection.id,
            image_attachment: {
              content: reader.result,
              filename: name,
              content_type: type
            }
          };

          api.createBackground(payload).then(
            result => {
              upload.uploaded += 1;
              if (upload.total === upload.uploaded) table.getData();
            },
            error => {
              alert('Error in uploading Background image');
            }
          );
        },
        false
      );

      reader.readAsDataURL(file);
    }
  }

  render() {
    const collection = this.props.collection;
    const title = `Backgrounds (collection "${collection.name}")`;

    return (
      <EditableTable
        title={title}
        columns={this.columns}
        initialSort={this.initialSort}
        data={this.state.data}
        collection={collection}
        getDataFunc={() => this.getData()}
        updateFunc={api.updateBackground}
        deleteFunc={api.deleteBackground}
        customAction="Upload Backgrounds"
        customActionFunc={this.uploadBackgrounds}
        customActionType="upload"
        className="editable-table"
      />
    );
  }
}

export default BackgroundsTable;
