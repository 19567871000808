import React, { Component } from 'react';
import EditableTable from '../../Shared/EditableTable';
import LayoutNodesModal from './LayoutNodesModal';
import LayoutThemesModal from './LayoutThemesModal';
import * as api from '../../api/lab-api';
import { connect } from 'react-redux';
import { labIdSelector, labSelector } from '../../selectors';

class LayoutsTable extends Component {
  state = { data: [], error: null, labThemeProperties: null, currentLayout: null, editNodes: false, editThemes: false };

  columns = [
    { formatter: "rownum", align: "center", width: 50, headerSort: false },
    { title: '', field: 'published_at', width: 60, headerSort: false, cssClass: 'draft' },
    { title: 'ID*', field: 'ex_id', width: 250, editor: 'input', formatter: 'textarea', headerSort: false },
    { title: 'DP2 Filename*', field: 'layout_filename', width: 250, editor: 'input', formatter: "textarea" },
    {
      title: 'Themes',
      field: 'themes_count',
      width: 80,
      headerSort: false,
      align: 'center',
      cssClass: 'cell-link',
      cellClick: this.themesCountClick,
      tableComponent: this
    },
    {
      title: 'Nodes',
      field: 'nodes_list',
      width: 500,
      headerSort: false,
      formatter: 'textarea',
      cssClass: 'cell-link',
      cellClick: this.nodesListClick,
      tableComponent: this
    }
  ];

  initialSort = [{ column: 'ex_id', dir: 'asc' }];

  constructor(props) {
    super(props);
    this.getLabThemeProperties();
  }

  nodesListClick(e, cell) {
    e.preventDefault();
    const data = cell._cell.row.data;
    const self = cell._cell.column.definition.tableComponent;
    self.setState({ currentLayout: data, editNodes: true, editThemes: false, scrollY: window.scrollY });
  }

  themesCountClick(e, cell) {
    e.preventDefault();
    const data = cell._cell.row.data;
    const self = cell._cell.column.definition.tableComponent;
    self.setState({ currentLayout: data, editNodes: false, editThemes: true, scrollY: window.scrollY });
  }

  getLabThemeProperties() {
    const { labId } = this.props;
    api.getThemeProperties(labId).then(
      result => {
        this.setState({ labThemeProperties: result.data });
      },
      error => {
        alert('Error in loading Lab Theme Properties');
      }
    );
  }

  handleNodesModalClose() {
    this.getData();
  }

  getData() {
    const { labId } = this.props;

    api.getLabLayouts(labId).then(
      result => {
        this.setState({ data: result.data, currentLayout: null, editNodes: false, editThemes: false });
      },
      error => {
        alert('Error in loading Lab Layouts');
      }
    );
  }

  layoutsLoaded(data) {
    if (this.state.scrollY) window.scrollTo({ top: this.state.scrollY });
  }

  render() {
    const { currentLayout, editNodes, editThemes, labThemeProperties } = this.state;
    const { labId, lab } = this.props;
    const createParams = { lab_id: labId };

    return (
      labThemeProperties && (
        <div>
          {editNodes && (
            <LayoutNodesModal onCancel={() => this.handleNodesModalClose()} layout={currentLayout} labId={labId} />
          )}
          {editThemes && (
            <LayoutThemesModal
              onCancel={() => this.handleNodesModalClose()}
              layout={currentLayout}
              labId={labId}
              lab={lab}
              themeProperties={labThemeProperties}
            />
          )}
          <EditableTable
            title="Product Layouts"
            columns={this.columns}
            initialSort={this.initialSort}
            data={this.state.data}
            getDataFunc={() => this.getData()}
            createParams={createParams}
            createFunc={api.createLayout}
            updateFunc={api.updateLayout}
            deleteFunc={api.deleteLayout}
            dataLoadedFunc={(data) => this.layoutsLoaded(data)}
            className="editable-table"
          />
        </div>
      )
    );
  }
}

const mapStateToProps = state => ({
  labId: labIdSelector(state),
  lab: labSelector(state)
});

export default connect(mapStateToProps)(LayoutsTable);
