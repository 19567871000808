import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { object, array } from "prop-types";

// Plugins
import RetinaImage from "react-retina-image";

// Components
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import ExpiredSessionModal from "../ExpiredSessionModal";

// Helpers
import { logoutRequest } from "../../Login/actions";
import { changeCurrentLabRequest } from "../../User/actions";

// Images
import logo from "../../images/web-Logo@1x.png";
import logoRetina from "../../images/web-Logo@2x.png";

// Styles
import "./header.css";
import "react-toastify/dist/ReactToastify.min.css";

const mapStateToProps = (state, ownProps) => {
  const {
    login: { user, lab, labs }
  } = state;

  return { user, lab, labs };
};

const mapDispatchToProps = { logoutRequest, changeCurrentLabRequest };

class Header extends Component {
  state = {
    isResponsiveNavOpen: false
  };

  static propTypes = {
    location: object,
    user: object,
    labs: array,
    lab: object
  };

  static defaultProps = {
    location: {},
    user: {},
    labs: [],
    lab: {}
  };

  logout = () => {
    const { logoutRequest } = this.props;
    logoutRequest();
  };

  toggleResponsiveNav = () => {
    this.setState({
      isResponsiveNavOpen: !this.state.isResponsiveNavOpen
    });
  };

  isFixed = () => {
    return false;
  };

  labOptions = () => {
    let options = this.props.labs.map(row => {
      return {
        label: row.name,
        value: row.id,
        selected: false
      };
    });

    return options;
  };

  changeCurrentLab = option => {
    if (option && option.value) {
      const { changeCurrentLabRequest, labs } = this.props;
      let lab = labs.find(row => row.id === option.value);

      changeCurrentLabRequest(lab);
    }
  };

  toggleResponsiveNav = () => {
    this.setState({
      isResponsiveNavOpen: !this.state.isResponsiveNavOpen
    });
  };

  render() {
    const { user, lab } = this.props;
    const currentLabOption = { value: lab.id, label: lab.name };

    return (
      <header className="header">
        <ToastContainer position={toast.POSITION.TOP_CENTER} autoClose={8000} />
        <ExpiredSessionModal />
        <aside className={`navigation ${this.state.isResponsiveNavOpen ? "navigation--active" : ""}`}>
          <header className="navigation__header navigation__hide-desktop">
            <NavLink className="navigation__logo" to="/dashboard">
              <RetinaImage src={[logo, logoRetina]} />
            </NavLink>
            <i
              className={`hamburger-menu ${this.state.isResponsiveNavOpen ? "hamburger-menu--active" : ""}`}
              onClick={this.toggleResponsiveNav}
            >
              <span />
              <span />
              <span />
              <span />
            </i>
          </header>
          <nav
            className={`navigation__container ${this.state.isResponsiveNavOpen ? "navigation__container--active" : ""}`}
            onClick={this.toggleResponsiveNav}
          >
            <NavLink className="navigation__logo navigation__hide-mobile" to="/dashboard">
              <RetinaImage src={[logo, logoRetina]} />
            </NavLink>
            <ul className="navigation__main">
              <li className="navigation__item">
                <NavLink to="/dashboard" className="navigation__link" activeClassName="navigation__link--active">
                  Dashboard
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink to="/products" className="navigation__link" activeClassName="navigation__link--active">
                  Products
                </NavLink>
              </li>
              <li className="navigation__item">
                <NavLink to="/layouts" className="navigation__link" activeClassName="navigation__link--active">
                  Layouts
                </NavLink>
              </li>
              {lab.studio_default_id && (
                <li className="navigation__item">
                  <NavLink to="/studios" className="navigation__link" activeClassName="navigation__link--active">
                    Studios
                  </NavLink>
                </li>
              )}
              {lab.allow_bulk_shipping && (
                <li className="navigation__item">
                  <NavLink
                    to="/bulk-ship-products"
                    className="navigation__link"
                    activeClassName="navigation__link--active"
                  >
                    Bulk Ship Products
                  </NavLink>
                </li>
              )}
              {lab.allow_backgrounds && (
                <li className="navigation__item">
                  <NavLink to="/backgrounds" className="navigation__link" activeClassName="navigation__link--active">
                    Backgrounds
                  </NavLink>
                </li>
              )}
              {lab.key === "reedy" && (
                <li className="navigation__item">
                  <NavLink to="/tools" className="navigation__link" activeClassName="navigation__link--active">
                    Tools
                  </NavLink>
                </li>
              )}
            </ul>
            <ul className="navigation__user">
              {user.is_photoday && (
                <li className="topnav__item topnav__item--admin">
                  <Select
                    name="lab"
                    isSearchable={true}
                    className="header-lab-select topnav__hide-mobile"
                    value={currentLabOption}
                    options={this.labOptions()}
                    placeholder="Select a Lab..."
                    onChange={this.changeCurrentLab}
                    inputProps={{ autoComplete: "nope", autofill: "off" }}
                  />
                </li>
              )}
              <li className="navigation__item" onClick={this.logout}>
                Log Out
              </li>
            </ul>
          </nav>
        </aside>
      </header>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
