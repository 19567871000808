import React, { Component } from 'react';
import EditableTable from '../../Shared/EditableTable';
import ThemePropertyValuesModal from './ThemePropertyValuesModal';
import * as api from '../../api/lab-api';
import { connect } from 'react-redux';
import { labIdSelector } from '../../selectors';

class ThemePropertiesTable extends Component {
  state = { isLoaded: false, data: [], error: null, currentThemeProperty: null };

  columns = [
    { title: '', field: 'published_at', width: 60, headerSort: false, cssClass: 'draft' },
    { title: 'Position', field: 'position', width: 100, editor: 'number', editorParams: { min: 0 } },
    { title: 'Title', field: 'title', width: 150, editor: 'input' },
    { title: 'Code', field: 'code', width: 120, editor: 'input', headerSort: false },
    {
      title: 'Primary',
      field: 'primary',
      width: 75,
      align: 'center',
      headerSort: false,
      editor: true,
      formatter: 'tickCross'
    },
    {
      title: 'Filterable',
      field: 'filterable',
      width: 80,
      align: 'center',
      headerSort: false,
      editor: true,
      formatter: 'tickCross'
    },
    {
      title: 'Values',
      field: 'values_count',
      width: 65,
      align: 'center',
      headerSort: false,
      cssClass: 'cell-link',
      cellClick: this.valuesClick,
      tableComponent: this
    }
  ];

  initialSort = [{ column: 'position', dir: 'asc' }];

  valuesClick(e, cell) {
    e.preventDefault();
    const data = cell._cell.row.data;
    const self = cell._cell.column.definition.tableComponent;
    self.setState({ currentThemeProperty: data });
  }

  getData() {
    const { labId } = this.props;
    api.getThemeProperties(labId).then(
      result => {
        this.setState({ isLoaded: true, data: result.data, currentThemeProperty: null });
      },
      error => {
        alert('Error in loading Theme Properties');
      }
    );
  }

  render() {
    const { labId } = this.props;
    const createParams = { lab_id: labId };
    const { currentThemeProperty } = this.state;

    return (
      <div>
        {currentThemeProperty && (
          <ThemePropertyValuesModal themeProperty={currentThemeProperty} onCancel={() => this.getData()} />
        )}
        <EditableTable
          title="Theme Properties"
          columns={this.columns}
          initialSort={this.initialSort}
          data={this.state.data}
          getDataFunc={() => this.getData()}
          createParams={createParams}
          createFunc={api.createThemeProperty}
          updateFunc={api.updateThemeProperty}
          deleteFunc={api.deleteThemeProperty}
          className="editable-table"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  labId: labIdSelector(state)
});

export default connect(mapStateToProps)(ThemePropertiesTable);
