import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { logoutRequest } from '../../Login/actions';

const mapStateToProps = state => ({
  showModal: state.login.showExpiredSessionModal
});

const mapDispatchToProps = { logoutRequest };

class ExpiredSessionModal extends Component {
  close = evt => {
    evt.preventDefault();
    this.props.logoutRequest();
  };
  render() {
    const { showModal } = this.props;
    return (
      <Modal id="expired-session-modal" show={showModal} onHide={this.close} backdrop="static" keyboard={false}>
        <Modal.Header>
          <h4 className="center-header-text">Your Session has Expired!</h4>
        </Modal.Header>
        <Modal.Body>
          <p>Your session has expired. By clicking on the close button you will be redirected to the login page.</p>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={this.close} type="button" className="btn btn-primary center-block">
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ExpiredSessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpiredSessionModal);
