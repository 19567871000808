import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as storage from '../../utils/storage';

const LAST_USER = process.env.REACT_APP_LAST_USER;

const mapStateToProps = state => ({
  login: state.login
});

const ReturningUserRoute = ({ component: Component, ...rest }) => {
  const {
    login: { isAuthenticated, user_id },
    location: { state }
  } = rest;
  const pathname = state && state.from && state.from.pathname;
  const lastUserId = storage.get(LAST_USER);

  if (isAuthenticated && pathname && (!lastUserId || lastUserId === user_id)) {
    return <Redirect to={pathname} />;
  }
  if (isAuthenticated) return <Redirect to="/dashboard" />;

  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default connect(mapStateToProps)(ReturningUserRoute);
