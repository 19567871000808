import React, { Component } from 'react';
import EditableTable from '../../Shared/EditableTable';
import * as api from '../../api/lab-api';
import { connect } from 'react-redux';
import { reactFormatter } from 'react-tabulator';
import EditableImageCell from '../../Shared/EditableTable/EditableImageCell';
import { labIdSelector } from '../../selectors';

class ProductCategoriesTable extends Component {
  state = { isLoaded: false, data: [], error: null };

  columns = [
    { title: '', field: 'published_at', width: 60, headerSort: false, cssClass: 'draft' },
    { title: 'Position', field: 'display_priority', width: 100, editor: 'number', editorParams: { min: 0 } },
    { title: 'Name', field: 'name', width: 200, editor: 'input' },
    {
      title: 'Width=500',
      field: 'thumbnail_url',
      width: 90,
      headerSort: false,
      align: 'center',
      formatter: reactFormatter(<EditableImageCell attachmentField="thumbnail" updateFunc={api.updateProductCategory} />)
    },
    { title: 'Icon', field: 'icon', width: 100, editor: 'input' }
  ];

  initialSort = [{ column: 'display_priority', dir: 'asc' }];

  getData() {
    const { labId } = this.props;
    api.getProductCategories({ lab_id: labId }).then(
      result => {
        this.setState({ isLoaded: true, data: result.data });
      },
      error => {
        alert('Error in loading Product Categories');
      }
    );
  }

  render() {
    const { labId } = this.props;
    const createParams = { lab_id: labId };

    return (
      <div>
        <EditableTable
          title="Product Categories"
          columns={this.columns}
          initialSort={this.initialSort}
          data={this.state.data}
          getDataFunc={() => this.getData()}
          createParams={createParams}
          createFunc={api.createProductCategory}
          updateFunc={api.updateProductCategory}
          deleteFunc={api.deleteProductCategory}
          className="editable-table"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  labId: labIdSelector(state)
});

export default connect(mapStateToProps)(ProductCategoriesTable);
