import React, { Component } from 'react';
import Select from 'react-select';

class ProductLayoutsCell extends Component {
  constructor(props) {
    super(props);

    const cell = this.props.cell._cell;
    const layouts = cell.row.data.layouts || [];
    const layoutIds = layouts.map(layout => layout.id);
    this.state = { value: layoutIds };
  }

  handleChange = value => {
    const cell = this.props.cell._cell;
    const layoutIds = value.map(el => el.value);
    const layoutIdsCell = cell.row.cells.find(el => el.column.field === 'layout_ids');

    this.setState({ value: layoutIds });
    layoutIdsCell.setValue(layoutIds);
  };

  componentDidMount() {
    const row = this.props.cell._cell.row;
    row.normalizeHeight(true);
  }

  componentDidUpdate() {
    const row = this.props.cell._cell.row;
    row.normalizeHeight(true);
  }

  render() {
    const cell = this.props.cell._cell;
    const tableComponent = cell.column.definition.tableComponent;
    const editable = cell.row.data.published_at === null;
    const layouts = tableComponent.state.labLayouts;
    const value = this.state.value;
    const layoutOptions = layouts.map(l => Object.assign({}, { value: l.id, label: l.ex_id }));
    const selectedLayouts = layoutOptions.filter(l => value && value.includes(l.value))

    return (
      <div className="select-container">
        <Select
          value={selectedLayouts}
          isDisabled={!editable}
          onChange={this.handleChange}
          isMulti={true}
          isClearable={false}
          className="full-width"
          options={layoutOptions}
          placeholder="select..."
        />
      </div>
    );
  }
}

export default ProductLayoutsCell;
