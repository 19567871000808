import React, { Component } from 'react';

class EditableImageCell extends Component {
  constructor(props) {
    super(props);

    const cell = this.props.cell._cell;
    this.attachmentField = this.props.attachmentField || 'image';
    this.imageWidth = this.props.imageWidth || 75;
    const attachmentUrl = `${this.attachmentField}_url`;
    const imageUrl = cell.row.data[attachmentUrl];
    this.state = { imageUrl: imageUrl };
  }

  handleUpload = evt => {
    const rowId = this.props.cell._cell.row.data.id;
    const image = evt.target.files[0];

    if (image) {
      const { name, type } = image;
      const reader = new FileReader();
      const attachmentField = `${this.attachmentField}_attachment`;
      const attachmentUrl = `${this.attachmentField}_url`;

      reader.addEventListener(
        'load',
        () => {
          let payload = { id: rowId };
          payload[attachmentField] = {
            content: reader.result,
            filename: name,
            content_type: type
          };
          this.props.updateFunc(payload).then(
            result => {
              this.setState({ imageUrl: result.data[attachmentUrl] });
            },
            error => {
              alert('Photo upload failed');
            }
          );
        },
        false
      );

      reader.readAsDataURL(image);
    }
  };

  normalizeRowHeight() {
    const row = this.props.cell._cell.row;
    setTimeout(function() {
      row.normalizeHeight(true);
    }, 500);
    setTimeout(function() {
      row.normalizeHeight(true);
    }, 4000);
  }

  componentDidMount() {
    this.normalizeRowHeight();
  }

  componentDidUpdate() {
    this.normalizeRowHeight();
  }

  render() {
    const imageUrl = this.state.imageUrl;
    const cell = this.props.cell._cell
    const data = cell.row.data;
    const rowId = data.id;
    const tableProps = cell.column.definition.editableTableComponent.props;
    const user = tableProps.login.user;
    const editable = user.is_photoday || data.published_at === null || data.published_at === undefined;

    return (
      <div>
        {imageUrl && <img src={imageUrl} width={this.imageWidth} alt="" />}
        {rowId && editable && <input type="file" accept="image/jpeg,image/png" onChange={this.handleUpload} />}
      </div>
    );
  }
}
export default EditableImageCell;
