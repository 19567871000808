import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import browser_outdated from './images/browser_out_of_date.png';

const APP_LAB_URL = process.env.REACT_APP_LAB_URL;

//Sentry.init({
//  dsn: 'https://c7a233084d7d4150a1c56cc028c433cd@sentry.io/1415708'
//});

class ErrorBoundary extends Component {
  state = {
    error: null,
    outdated: false
  };

  static getDerivedStateFromError(error) {
    if (!('serviceWorker' in navigator) && !('orientation' in window)) {
      return { outdated: true, error: true };
    } else {
      return { error: true };
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error, outdated } = this.state;

    if (outdated && error) {
      // render fallback UI
      return (
        <React.Fragment>
          <div className="navigation" />
          <div className="error-boundary">
            <h2>Oops! Something went wrong</h2>
            <h4>Your browser may need to be updated</h4>
            <img src={browser_outdated} alt="sad computer" className="error-boundary__image" />
            <h4>Please review the link below</h4>
            <h5>
              <a
                href="http://outdatedbrowser.com/"
                className="error-boundary__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://outdatedbrowser.com
              </a>
            </h5>
          </div>
        </React.Fragment>
      );
    } else if (error) {
      return (
        <React.Fragment>
          <div className="navigation" />
          <div className="error-boundary">
            <h2>Oops! Something went wrong</h2>
            <img src={browser_outdated} alt="sad computer" className="error-boundary__image" />
            <h5>
              <a href={APP_LAB_URL} className="error-boundary__link">
                Click here to refresh the screen
              </a>
            </h5>
          </div>
        </React.Fragment>
      );
    } else {
      // when there's not an error, render children untouched
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
