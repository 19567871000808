import 'babel-polyfill';
import 'scrolling-element';

import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';


// CSS
import './Loader/Spinner.svg';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import './styles/master.css';

import Routes from './routes';

const routes = Routes();

ReactDOM.render(routes, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
