import * as api from "../utils/http";
import setPagingSortFilter from "../utils/setPagingSortFilter";

export const orderList = (studioId, page, perPage = 15, search = null, order = null, dir = null) => {
  const endpoint = `orders`;
  const args = setPagingSortFilter(page, perPage, search, order, dir);
  return api.get(endpoint, args).then(res => {
    const { data, headers } = res;
    const pager = api.getPaginationParams(headers);
    return { data, pager };
  });
};

export const orderDetail = (studioId, orderId) => {
  const endpoint = `orders/${orderId}`;
  return api.get(endpoint);
};

export const orderResend = orderNum => {
  const endpoint = `order-resend/${orderNum}`;

  return api.post(endpoint);
};
