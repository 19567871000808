import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import LayoutNodesTable from './LayoutNodesTable';
import './styles.css';

class LayoutNodesModal extends Component {
  cancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  render() {
    const { layout } = this.props;

    return (
      <Modal className="layout-nodes-modal" show={true} onHide={this.cancel} backdrop="static">
        <Modal.Header closeButton={true} onHide={this.cancel} />
        <Modal.Body>
          <LayoutNodesTable layout={layout} />
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-close" onClick={this.cancel}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LayoutNodesModal;
