import React, { Component } from 'react';
import EditableTable from '../../Shared/EditableTable';
import * as api from '../../api/lab-api';
import { connect } from 'react-redux';
import { labIdSelector } from '../../selectors';

class ShippingRatesTable extends Component {
  state = { isLoaded: false, data: [], error: null };

  columns = [
    { title: '', field: 'published_at', width: 60, headerSort: false, cssClass: 'draft' },
    { title: 'ID', field: 'ex_id', width: 180, editor: 'input', headerSort: false },
    { title: 'Description', field: 'description', width: 200, editor: 'input', headerSort: false },
    {
      title: 'Min order amount',
      field: 'min_amount_without_symbol',
      width: 140,
      editor: 'input',
      formatter: 'money',
      formatterParams: { symbol: '$' },
      headerSort: false
    },
    {
      title: 'Max order amount',
      field: 'max_amount_without_symbol',
      width: 140,
      editor: 'input',
      formatter: 'money',
      formatterParams: { symbol: '$' },
      headerSort: false
    },
    {
      title: 'Rate',
      field: 'rate_without_symbol',
      width: 85,
      editor: 'input',
      formatter: 'money',
      formatterParams: { symbol: '$' },
      headerSort: false
    },
    { title: 'Min transit days', field: 'minimum_transit_days', width: 120, editor: 'number', headerSort: false },
    { title: 'Max transit days', field: 'maximum_transit_days', width: 125, editor: 'number', headerSort: false },
    {
      title: 'Tracking',
      field: 'tracking',
      width: 80,
      align: 'center',
      headerSort: false,
      editor: true,
      formatter: 'tickCross'
    },
  ];

  getData() {
    const { labId } = this.props;
    api.getShippingRates({ lab_id: labId }).then(
      result => {
        this.setState({ isLoaded: true, data: result.data });
      },
      error => {
        alert('Error in loading Shipping Rates');
      }
    );
  }

  render() {
    const { labId } = this.props;
    const createParams = { lab_id: labId };

    return (
      <div>
        <EditableTable
          title="Shipping Rates"
          columns={this.columns}
          initialSort={this.initialSort}
          data={this.state.data}
          getDataFunc={() => this.getData()}
          createParams={createParams}
          createFunc={api.createShippingRate}
          updateFunc={api.updateShippingRate}
          deleteFunc={api.deleteShippingRate}
          className="editable-table"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  labId: labIdSelector(state)
});

export default connect(mapStateToProps)(ShippingRatesTable);
