/**
 * Store Value into localStorage
 * @param  {String} key   Key used to store value
 * @param  {String} value Value to be stored
 * @param  {Boolean} isLocal local vs session
 */
export const set = (key, value, isLocal = true) => {
  try {
    isLocal ? window.localStorage.setItem(key, value) : window.sessionStorage.setItem(key, value);
  } catch (err) {
    // Just catch the error
  }
};

/**
 * Retrieve a value from localStorage
 * @param  {String} key Key used to retrieve Value
 * @return {String}     Value being retrieved
 */
export const get = key => {
  try {
    return window.localStorage.getItem(key) || window.sessionStorage.getItem(key);
  } catch (err) {
    return undefined;
  }
};

/**
 * Remove a key from localStorage
 * @param  {String} key Key to be removed
 * @param  {Boolean} isLocal local vs session
 * @return {[type]}     [description]
 */
export const remove = (key, isLocal = true) => {
  try {
    return isLocal ? window.localStorage.removeItem(key) : window.sessionStorage.removeItem(key);
  } catch (err) {
    // Catch error
  }

  return undefined;
};

/**
 * Clear all localStorage values
 */
export const clear = (isLocal = true) => {
  try {
    isLocal ? window.localStorage.clear() : window.sessionStorage.clear();
  } catch (e) {
    // catch error
  }
};

export const clearAll = () => {
  try {
    window.sessionStorage.clear();
    window.localStorage.clear();
  } catch (error) {
    return undefined;
  }
};
