import React, { Component } from 'react';
import BulkShipProductsTable from './table';

class BulkShipProductList extends Component {
  render() {
    return (
      <div>
        <aside className="subnavigation">
          <nav className="subnavigation__container">
            <header className="subnavigation__header subnavigation__hide-mobile">Bulk Ship Products</header>
          </nav>
        </aside>
        <div className="container">
          <div className="QuickGlanceTables">
            <BulkShipProductsTable />
          </div>
        </div>
      </div>
    );
  }
}

export default BulkShipProductList;
