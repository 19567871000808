import React, { Component } from 'react';
import EditableTable from '../../Shared/EditableTable';
import * as api from '../../api/lab-api';
import { connect } from 'react-redux';
import { labSelector } from '../../selectors';

class StudiosTable extends Component {
  state = { isLoaded: false, data: [], error: null };

  columns = [
    { title: 'Account #', field: 'studio_ex_id', width: 120, editor: 'input' },
    { title: 'Name', field: 'name', formatter: 'textarea', width: 270 },
    { title: 'Registered', field: 'registered_at', width: 120 },
    { title: 'E-mail', field: 'email', formatter: 'textarea', width: 250 },
    { title: 'Phone', field: 'phone', width: 120, headerSort: false },
    { title: 'Address', field: 'full_address', formatter: 'textarea', headerSort: false }
  ];

  getData(query) {
    const { lab } = this.props;
    api.getStudios({ lab_id: lab.id, query: query }).then(
      result => {
        this.setState({ isLoaded: true, data: result.data });
      },
      error => {
        alert('Error in loading Studios');
      }
    );
  }

  render() {
    const { lab } = this.props;
    const updateParams = { lab_id: lab.id };

    return (
      <div>
        {lab && lab.studio_default_id && (
          <EditableTable
            title="Studios"
            columns={this.columns}
            initialSort={this.initialSort}
            data={this.state.data}
            getDataFunc={(query) => this.getData(query)}
            updateFunc={api.updateStudio}
            updateParams={updateParams}
            className="editable-table"
            titleSearch="Account # or Name"
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lab: labSelector(state)
});

export default connect(mapStateToProps)(StudiosTable);
