import { call, takeLatest, all } from 'redux-saga/effects';

import * as types from './constants';
import * as storage from '../utils/storage';
import * as actions from './actions';

import errorHandler from '../utils/errorResponseHandler';

const LOGIN_ENTITIES = process.env.REACT_APP_LOGIN_ENTITIES;

function* changeCurrentLabRequestFlow(action) {
  try {
    const {
      payload: { lab }
    } = action;
    const json = yield call(storage.get, LOGIN_ENTITIES);
    let loginEntities = JSON.parse(json);
    const labEntity = { [lab.id]: lab };

    loginEntities.lab= labEntity;
    storage.set(LOGIN_ENTITIES, JSON.stringify(loginEntities), true);
    window.location.reload();
  } catch (error) {
    yield call(errorHandler, actions.changeCurrentLabRequestError, error);
  }
}


function* getUserWatcher() {
  yield all([
    takeLatest(types.CHANGE_CURRENT_LAB_REQUESTING, changeCurrentLabRequestFlow)
  ]);
}

export default getUserWatcher;
