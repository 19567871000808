import React, { Component } from 'react';
import { reactFormatter } from 'react-tabulator';
import EditableTable from '../../Shared/EditableTable';
import EditableImageCell from '../../Shared/EditableTable/EditableImageCell';
import StudiosCell from './StudiosCell';
import * as api from '../../api/lab-api';
import * as studioApi from '../../api/studio-api';

class LayoutThemesTable extends Component {
  columns = [
    { formatter: "rownum", align: "center", width: 50, headerSort: false, allStudios: null },
    { title: '', field: 'published_at', width: 60, headerSort: false, cssClass: 'draft', manage: true },
    {
      title: 'Width=510',
      field: 'image_url',
      width: 90,
      headerSort: false,
      align: 'center',
      cssClass: 'theme-photo',
      formatter: reactFormatter(<EditableImageCell updateFunc={api.updateLayoutTheme} />)
    },
    { title: 'Name (for customer)', field: 'name', width: 180, editor: 'input', headerSort: false },
    { title: 'ID', field: 'ex_id', width: 200, editor: 'input', headerSort: false },
    { field: 'studio_ids', visible: false }
  ];

  studiosColumn = {
    title: 'Studios',
    field: 'studios',
    width: 220,
    headerSort: false,
    formatter: reactFormatter(<StudiosCell />),
    tableComponent: this,
    cssClass: 'overflow-visible'
  }

  initialSort = [
    //{column: 'layout_node_type', dir: 'asc' }
  ];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      allStudios: [],
      primaryProp: this.getPrimaryProp(),
      primaryPropValue: this.getPrimaryPropValue()
    }
    if (this.props.lab.allow_custom_themes) this.getAllStudios();
  }

  getPrimaryProp() {
    const props = this.props.themeProperties;
    const primaryProp = props.find(prop => prop.primary);
    return primaryProp;
  }

  getPrimaryPropValue() {
    const values = this.props.layout.theme_primary_property_values;
    if (values.length === 0) return;
    const primaryPropValue = values[0].theme_property_value_id;
    return primaryPropValue;
  }

  getAllStudios() {
    studioApi.getAllStudios().then(
      result => {
        this.setState({ allStudios: result.data });
      },
      error => {
        alert('Error in loading Studios');
      }
    );
  }

  getData(propValue) {
    const layoutId = this.props.layout.id;

    api.getLayoutThemes(layoutId, propValue).then(
      result => {
        this.setState({ isLoaded: true, data: result.data, primaryPropValue: propValue });
      },
      error => {
        alert('Error in loading Layout Themes');
      }
    );
  }

  uploadThemePhotos(evt, table) {
    const files = evt.target.files;
    const layout = table.props.layout;
    let upload = { total: files.length, uploaded: 0 };

    for (let ind = 0; ind < files.length; ind++) {
      const file = files[ind];
      const { name, type } = file;
      const reader = new FileReader();

      reader.addEventListener(
        'load',
        () => {
          const payload = {
            layout_id: layout.id,
            lab_id: layout.lab_id,
            theme_property_value_id: table.props.titleFilterValue,
            image_attachment: {
              content: reader.result,
              filename: name,
              content_type: type
            }
          };

          api.createLayoutTheme(payload).then(
            result => {
              upload.uploaded += 1;
              if (upload.total === upload.uploaded) table.getData(table.props.titleFilterValue);
            },
            error => {
              alert('Error in creating new Layout Theme');
            }
          );
        },
        false
      );

      reader.readAsDataURL(file);
    }
  }

  getColumns() {
    let columns = this.columns;
    const props = this.props.themeProperties;
    for (let ind in props) {
      const prop = props[ind];
      let propertyValues = [];
      for (let i in prop.theme_property_values) {
        const val = prop.theme_property_values[i];
        const valTitle = `${val.value} (${val.ex_id})`;
        propertyValues.push({ label: valTitle, value: val.value });
      }
      const column = {
        title: prop.title,
        field: `property_values.${prop.code}`,
        width: 150,
        editor: 'select',
        editorParams: { values: propertyValues }
      };
      columns.push(column);
    }
    if (this.props.lab.allow_custom_themes) columns.push(this.studiosColumn);

    return columns;
  }

  getFilterOptions() {
    const values = this.props.layout.theme_primary_property_values;
    let options = values.map(val => Object.assign({}, { label: val.value, value: val.theme_property_value_id }));
    options.unshift({ label: 'Any', value: null });

    return options;
  }

  render() {
    const layout = this.props.layout;
    const { primaryProp, primaryPropValue } = this.state;
    const createParams = { lab_id: layout.lab_id, layout_id: layout.id };
    const title = `Layout Themes (layout "${layout.ex_id}")`;
    const columns = this.getColumns();
    const titleFilter = primaryProp ? primaryProp.title : null;

    return (
      <EditableTable
        title={title}
        columns={columns}
        initialSort={this.initialSort}
        data={this.state.data}
        layout={layout}
        getDataFunc={propValue => this.getData(propValue)}
        createParams={createParams}
        updateFunc={api.updateLayoutTheme}
        deleteFunc={api.deleteLayoutTheme}
        customAction="Upload Theme Photos"
        customActionFunc={this.uploadThemePhotos}
        customActionType="upload"
        titleFilter={titleFilter}
        titleFilterValue={primaryPropValue}
        titleFilterOptions={this.getFilterOptions()}
        className="editable-table"
      />
    );
  }
}

export default LayoutThemesTable;
