import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ThemePropertyValuesTable from './ThemePropertyValuesTable';
import './styles.css';

class ThemePropertyValuesModal extends Component {
  cancel = () => {
    const { onCancel } = this.props;
    onCancel && onCancel();
  };

  render() {
    const { themeProperty } = this.props;

    return (
      <Modal className="theme-property-values-modal" show={true} onHide={this.cancel} backdrop="static">
        <Modal.Header closeButton={true} onHide={this.cancel} />
        <Modal.Body>
          <ThemePropertyValuesTable themeProperty={themeProperty} />
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-close" onClick={this.cancel}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ThemePropertyValuesModal;
