import React, { Component } from 'react';
import Select from 'react-select';

class StudiosCell extends Component {
  constructor(props) {
    super(props);

    const cell = this.props.cell._cell;
    const studios = cell.row.data.studios || [];
    const studioIds = studios.map(row => row.id);
    this.state = { value: studioIds };
  }

  handleChange = value => {
    const cell = this.props.cell._cell;
    const studioIds = value.map(el => el.value);
    const studioIdsCell = cell.row.cells.find(el => el.column.field === 'studio_ids');

    this.setState({ value });
    studioIdsCell.setValue(studioIds);
  };

  componentDidMount() {
    const row = this.props.cell._cell.row;
    row.normalizeHeight(true);
  }

  componentDidUpdate() {
    const row = this.props.cell._cell.row;
    row.normalizeHeight(true);
  }

  render() {
    const tableComponent = this.props.cell._cell.column.definition.tableComponent;
    const studios = tableComponent.state.allStudios;
    const studioOptions = studios.map(row => Object.assign({}, { value: row.id, label: row.name }));
    const selectedOptions = studioOptions.filter(row => this.state.value && this.state.value.includes(row.value));

    return (
      <div className="select-container">
        <Select
          value={selectedOptions}
          onChange={this.handleChange}
          isMulti={true}
          isClearable={false}
          className="full-width"
          options={studioOptions}
          placeholder="select..."
        />
      </div>
    );
  }
}

export default StudiosCell;
