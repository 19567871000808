import * as types from './constants';

// Create nexus
export const createNexus = (payload, callback) => ({
  type: types.CREATE_NEXUS,
  payload: { payload, callback }
});

export const createNexusSuccess = payload => ({
  type: types.CREATE_NEXUS_SUCCESS,
  payload: { payload }
});

export const createNexusError = error => ({
  type: types.CREATE_NEXUS_ERROR,
  payload: { error }
});

// Read nexus list
export const getNexusList = (payload, callback) => ({
  type: types.GET_NEXUS_LIST,
  payload: { payload, callback }
});

export const getNexusListSuccess = payload => ({
  type: types.GET_NEXUS_LIST_SUCCESS,
  payload: { payload }
});

export const getNexusListError = error => ({
  type: types.GET_NEXUS_LIST_ERROR,
  payload: { error }
});

// Delete Nexus
export const deleteNexus = (payload, callback) => ({
  type: types.DELETE_NEXUS,
  payload: { payload, callback }
});

export const deleteNexusSuccess = payload => ({
  type: types.DELETE_NEXUS_SUCCESS,
  payload: { payload }
});

export const deleteNexusError = error => ({
  type: types.DELETE_NEXUS_ERROR,
  payload: { error }
});

export const getMyAccountStudioRequest = (payload, callback) => ({
  type: types.GET_MYACCOUNT_STUDIO_REQUESTING,
  payload: { payload, callback }
});

export const getMyAccountStudioRequestSuccess = studio => ({
  type: types.GET_MYACCOUNT_STUDIO_SUCCESS,
  payload: {
    studio
  }
});

export const getMyAccountStudioRequestError = error => ({
  type: types.GET_MYACCOUNT_STUDIO_ERROR,
  payload: {
    error
  }
});

export const updateMyAccountStudioRequest = (studio, successCallback, errorCallback) => ({
  type: types.UPDATE_MYACCOUNT_STUDIO_REQUESTING,
  studio,
  successCallback,
  errorCallback
});

export const updateMyAccountStudioRequestSuccess = studio => ({
  type: types.UPDATE_MYACCOUNT_STUDIO_SUCCESS,
  payload: {
    studio
  }
});

export const updateMyAccountStudioRequestError = error => ({
  type: types.UPDATE_MYACCOUNT_STUDIO_ERROR,
  payload: {
    error
  }
});

export const getMyAccountRequest = () => ({
  type: types.GET_MYACCOUNT_REQUESTING
});

export const getMyAccountRequestSuccess = account => ({
  type: types.GET_MYACCOUNT_SUCCESS,
  payload: {
    account
  }
});

export const getMyAccountRequestError = error => ({
  type: types.GET_MYACCOUNT_ERROR,
  payload: {
    error
  }
});

export const updateMyAccountRequest = accountInfo => ({
  type: types.UPDATE_MYACCOUNT_REQUESTING,
  accountInfo
});

export const updateMyAccountRequestSuccess = account => ({
  type: types.UPDATE_MYACCOUNT_SUCCESS,
  payload: {
    account
  }
});

export const updateMyAccountRequestError = error => ({
  type: types.UPDATE_MYACCOUNT_ERROR,
  payload: {
    error
  }
});

export const updateStripeAccountRequest = ({ studioId, code }) => ({
  type: types.UPDATE_STRIPE_ACCOUNT_REQUESTING,
  payload: {
    studioId,
    code
  }
});

export const updateStripeAccountRequestSuccess = studio => ({
  type: types.UPDATE_STRIPE_ACCOUNT_SUCCESS,
  payload: {
    studio
  }
});

export const updateStripeAccountRequestError = error => ({
  type: types.UPDATE_STRIPE_ACCOUNT_ERROR,
  payload: {
    error
  }
});

export const deleteStripeAccountRequest = studioId => ({
  type: types.DELETE_STRIPE_ACCOUNT_REQUESTING,
  payload: {
    studioId
  }
});

export const deleteStripeAccountRequestSuccess = studio => ({
  type: types.DELETE_STRIPE_ACCOUNT_SUCCESS,
  payload: {
    studio
  }
});

export const deleteStripeAccountRequestError = error => ({
  type: types.DELETE_STRIPE_ACCOUNT_ERROR,
  payload: {
    error
  }
});

export const getUserStudiosRequest = () => ({
  type: types.GET_USER_STUDIOS_REQUESTING
});

export const getUserStudiosRequestSuccess = ({ data }) => ({
  type: types.GET_USER_STUDIOS_SUCCESS,
  payload: {
    studios: data
  }
});

export const getUserStudiosRequestError = error => ({
  type: types.GET_USER_STUDIOS_ERROR,
  payload: {
    error
  }
});

export const changeCurrentLabRequest = lab => ({
  type: types.CHANGE_CURRENT_LAB_REQUESTING,
  payload: { lab }
});

export const changeCurrentLabRequestError = error => ({
  type: types.CHANGE_CURRENT_LAB_ERROR,
  payload: {
    error
  }
});

// Connect with PhotoLynx PLIC
export const getPhotoLynxPlicOauthUrl = (payload, callback) => ({
  type: types.GET_PHOTOLYNX_PLIC_OAUTH_URL,
  payload: { payload, callback }
});

export const getPhotoLynxPlicOauthUrlSuccess = payload => ({
  type: types.GET_PHOTOLYNX_PLIC_OAUTH_URL_SUCCESS,
  payload: { payload }
});

export const getPhotoLynxPlicOauthUrlError = error => ({
  type: types.GET_PHOTOLYNX_PLIC_OAUTH_URL_ERROR,
  payload: { error }
});

export const createPhotoLynxPlicConnect = (payload, callback) => ({
  type: types.CREATE_PHOTOLYNX_PLIC_CONNECT,
  payload: { payload, callback }
});

export const createPhotoLynxPlicConnectSuccess = payload => ({
  type: types.CREATE_PHOTOLYNX_PLIC_CONNECT_SUCCESS,
  payload: { payload }
});

export const createPhotoLynxPlicConnectError = error => ({
  type: types.CREATE_PHOTOLYNX_PLIC_CONNECT_ERROR,
  payload: { error }
});

// Subscriptions
export const createSubscription = (payload, callback) => ({
  type: types.CREATE_SUBSCRIPTION,
  payload: { payload, callback }
});

export const createSubscriptionSuccess = payload => ({
  type: types.CREATE_SUBSCRIPTION_SUCCESS,
  payload: { payload }
});

export const createSubscriptionError = error => ({
  type: types.CREATE_SUBSCRIPTION_ERROR,
  payload: { error }
});

export const getSubscriptions = (payload, callback) => ({
  type: types.GET_SUBSCRIPTIONS,
  payload: { payload, callback }
});

export const getSubscriptionsSuccess = payload => ({
  type: types.GET_SUBSCRIPTIONS_SUCCESS,
  payload: { payload }
});

export const getSubscriptionsError = error => ({
  type: types.GET_SUBSCRIPTIONS_ERROR,
  payload: { error }
});

export const getSubscription = (payload, callback) => ({
  type: types.GET_SUBSCRIPTION,
  payload: { payload, callback }
});

export const getSubscriptionSuccess = payload => ({
  type: types.GET_SUBSCRIPTION_SUCCESS,
  payload: { payload }
});

export const getSubscriptionError = error => ({
  type: types.GET_SUBSCRIPTION_ERROR,
  payload: { error }
});

export const getSubscriptionPlans = (payload, callback) => ({
  type: types.GET_SUBSCRIPTION_PLANS,
  payload: { payload, callback }
});

export const getSubscriptionPlansSuccess = payload => ({
  type: types.GET_SUBSCRIPTION_PLANS_SUCCESS,
  payload: { payload }
});

export const getSubscriptionPlansError = error => ({
  type: types.GET_SUBSCRIPTION_PLANS_ERROR,
  payload: { error }
});

export const getSubscriptionCoupon = (payload, callback) => ({
  type: types.GET_SUBSCRIPTION_COUPON,
  payload: { payload, callback }
});

export const getSubscriptionCouponSuccess = payload => ({
  type: types.GET_SUBSCRIPTION_COUPON_SUCCESS,
  payload: { payload }
});

export const getSubscriptionCouponError = error => ({
  type: types.GET_SUBSCRIPTION_COUPON_ERROR,
  payload: { error }
});

export const removeSubscriptionCoupon = () => ({
  type: types.REMOVE_SUBSCRIPTION_COUPON
});

export const deleteSubscription = (payload, callback) => ({
  type: types.DELETE_SUBSCRIPTION,
  payload: { payload, callback }
});

export const deleteSubscriptionSuccess = payload => ({
  type: types.DELETE_SUBSCRIPTION_SUCCESS,
  payload: { payload }
});

export const deleteSubscriptionError = error => ({
  type: types.DELETE_SUBSCRIPTION_ERROR,
  payload: { error }
});

// Read studio labs in studio
export const getStudioLabsInStudio = (payload, callback) => ({
  type: types.GET_STUDIO_LABS_IN_STUDIO,
  payload: { payload, callback }
});

export const getStudioLabsInStudioSuccess = payload => ({
  type: types.GET_STUDIO_LABS_IN_STUDIO_SUCCESS,
  payload: { payload }
});

export const getStudioLabsInStudioError = error => ({
  type: types.GET_STUDIO_LABS_IN_STUDIO_ERROR,
  payload: { error }
});

// Add studio lab to studio
export const addStudioLabtoStudio = (payload, callback) => ({
  type: types.ADD_STUDIO_LAB_TO_STUDIO,
  payload: { payload, callback }
});

export const addStudioLabtoStudioSuccess = payload => ({
  type: types.ADD_STUDIO_LAB_TO_STUDIO_SUCCESS,
  payload: { payload }
});

export const addStudioLabtoStudioError = error => ({
  type: types.ADD_STUDIO_LAB_TO_STUDIO_ERROR,
  payload: { error }
});

// Read All Labs
export const getAllLabs = (payload, callback) => ({
  type: types.GET_ALL_LABS,
  payload: { payload, callback }
});

export const getAllLabsSuccess = payload => ({
  type: types.GET_ALL_LABS_SUCCESS,
  payload: { payload }
});

export const getAllLabsError = error => ({
  type: types.GET_ALL_LABS_ERROR,
  payload: { error }
});
